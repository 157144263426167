import React, { useRef, useEffect } from 'react';
import cl from './Loader.module.css';

const Loader = ({ showProgress, showOverlay, style, onTimeout }) => {
    const timerRef = useRef();

    useEffect(() => {
        if (!onTimeout) return;
        timerRef.current = setTimeout(() => {
            onTimeout();
        }, 10000);
        return () => {
            clearTimeout(timerRef.current);
        };
    }, []);

    return (
        <div
            className={
                showOverlay ? cl.landingLoaderOverlay : cl.landingLoaderCont
            }
            style={style}
        >
            <div
                className={`${cl.landingLoader} ${showProgress ? cl.progress : ''}`}
            >
                <div className={cl.loader}>
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </div>
            {showProgress && (
                <div className={cl.circleWrap}>
                    <div className={cl.circle}>
                        <div className={cl.mask + ' ' + cl.full}>
                            <div className={cl.fill} />
                        </div>
                        <div className={cl.mask}>
                            <div className={cl.fill} />
                        </div>
                        <div className={cl.insideCircle} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Loader;
