import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../../../hooks/useFetching';
import ProgressService from '../../../../../../api/ProgressService';
import { ToggleButton } from '../../../../../../teacherComponents';
import {
    Input,
    MicroButton,
    Tooltip,
} from '../../../../../../teacherComponents';
import { ReactComponent as IconBin } from '../../../../../../assets/svg/lessons-bin.svg';
import WordCard from '../WordCard/WordCard';
import { LanguageLevels } from '../../../../../../data/common';
import cl from './CategoryWords.module.css';

const CategoryWords = ({
    dictionary,
    category,
    currentWord,
    setCurrentWord,
    addedWord,
    setAddedWord,
    pendingWords = [],
    onAdd,
    onDelete,
    onUpdate,
    isInvalid,
    setIsInvalid,
}) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;

    const { id, level, title, words } = category;

    const [difficulty, setDifficulty] = useState('');
    const [fetchedWords, setFetchedWords] = useState([]);
    const [inputTimeout, setInputTimeout] = useState();
    const [spellcheckError, setSpellcheckError] = useState(false);
    const [newTitle, setNewTitle] = useState(title);

    const [getCategoryWords] = useFetching(async () => {
        const { data } = await ProgressService.getCategoryWords({
            categoryId: id,
            lang,
        });
        setFetchedWords(data.items);
        setCurrentWord(data.items[0]);
    });

    const [updateCategory] = useFetching(async ({ level, title }) => {
        await ProgressService.updateWordsCategory({
            id,
            level,
            title,
        });
    });

    const handleDifficultyChange = (newDifficulty) => {
        const newLevel = LanguageLevels.indexOf(newDifficulty);
        if (level === newLevel) return;

        updateCategory({ level: newLevel });
        onUpdate({ ...category, level: newLevel });
    };

    const hanleTitleChange = () => {
        if (newTitle === title) return;
        if (!newTitle.length) {
            setNewTitle(title);
        }
        updateCategory({ title: newTitle });
        onUpdate({ ...category, title: newTitle });
    };

    const handleWordInput = (text) => {
        setSpellcheckError(false);
        if (inputTimeout) {
            clearTimeout(inputTimeout);
        }

        const timeoutId = setTimeout(() => {
            setSpellcheckError(!dictionary.check(text));
        }, 500);

        setInputTimeout(timeoutId);
        setAddedWord(text);
    };

    useEffect(() => {
        const difficulty = level !== undefined ? LanguageLevels[level] : '';
        setDifficulty(difficulty);
        if (!words) {
            getCategoryWords();
        }
    }, [category]);

    useEffect(() => {
        setNewTitle(title);
    }, [title]);

    return (
        <div className={cl.category}>
            <div className={cl.titleContainer}>
                <Input
                    className={cl.title}
                    value={newTitle}
                    onChange={setNewTitle}
                    onBlur={hanleTitleChange}
                />
                {setAddedWord && (
                    <ToggleButton
                        style={{ width: 212 }}
                        label={t('users.level')}
                        options={LanguageLevels}
                        selectedOption={difficulty}
                        variant={'outlined'}
                        setOption={handleDifficultyChange}
                    />
                )}
            </div>
            <div className={cl.line} />
            {setAddedWord && (
                <div className={cl.inputContainer}>
                    {addedWord && spellcheckError && (
                        <Tooltip
                            text={t('exercises.check_spell')}
                            variant={'warning'}
                            style={{ top: -48, left: 25 }}
                        />
                    )}
                    <p>{'+'}</p>
                    <Input
                        onBlur={() =>
                            onAdd(
                                {
                                    word: addedWord.trim(),
                                    categoryId: category.id,
                                },
                                true
                            )
                        }
                        placeholder={t('exercises.add_word_to_category')}
                        value={addedWord}
                        onChange={handleWordInput}
                        onFocus={() => setCurrentWord()}
                        error={isInvalid ? t('exercises.invalid_word') : ''}
                        resetError={setIsInvalid}
                    />
                </div>
            )}

            <div className={cl.wordsList}>
                {(words ?? fetchedWords).map((w, i) => (
                    <div
                        className={`${cl.word} ${
                            currentWord?.word === w.word ? cl.active : ''
                        }`}
                        key={w.word}
                        onClick={() => setCurrentWord(w)}
                    >
                        <span>{`${i + 1}.`}</span>
                        <p>{w.word}</p>
                        {setAddedWord && (
                            <div className={cl.controls}>
                                <MicroButton
                                    icon={
                                        <IconBin
                                            style={{ filter: 'grayscale(1)' }}
                                        />
                                    }
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onDelete();
                                    }}
                                    size={'small'}
                                    variant={'grey'}
                                />
                            </div>
                        )}
                    </div>
                ))}
                {setAddedWord &&
                    pendingWords
                        .sort((a, b) => a.word.localeCompare(b.word))
                        .map((w, i) => (
                            <div
                                className={`${cl.word} ${w.invalid ? cl.invalid : cl.pending}`}
                                key={`${w.word}_${i}`}
                            >
                                <span>{`${(words ?? fetchedWords)?.length + i + 1}.`}</span>
                                <p>{w.word}</p>
                                <div className={cl.controls}>
                                    <MicroButton
                                        icon={
                                            <IconBin
                                                style={{
                                                    filter: 'grayscale(1)',
                                                }}
                                            />
                                        }
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onDelete(w);
                                        }}
                                        size={'small'}
                                        variant={'grey'}
                                    />
                                </div>
                            </div>
                        ))}
            </div>

            <WordCard
                currentWord={currentWord}
                category={category}
                onClose={() => setCurrentWord()}
                lang={lang}
            />
        </div>
    );
};

export default CategoryWords;
