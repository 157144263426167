import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import onboardingStore from '../../store/onboarding';
import UserService from '../../api/UserService';
import { useFetching } from '../../hooks/useFetching';
import { AuthContext, InterfaceContext } from '../../App';
import Loader from '../../components/UI/Loader/Loader';
import PageWrapper from '../../components/UI/PageWrapper/PageWrapper';
import { Button, Input, Label, Select } from '../../teacherComponents';
import { Roles } from '../../data/common';
import { ROUTES } from '../../router';
import { ReactComponent as IconLogout } from '../../assets/img/icon-logout.svg';
import cl from './Profile.module.css';

const Profile = () => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const { auth } = useContext(AuthContext);
    const { appInterface } = useContext(InterfaceContext);

    const { availableLanguages } = appInterface;
    const { user } = auth;
    const { role } = user;

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [sex, setSex] = useState('');

    const [updateUser, isLoading] = useFetching(async ({ lang }) => {
        const { id } = user;
        const res = await UserService.updateUser(
            lang ? { id, lang } : { id, name, sex }
        );

        if (res && res.status === 204) {
            auth.setUser(lang ? { ...user, lang } : { ...user, name, sex });
        }
    });

    useEffect(() => {
        setName(user.name ?? '');
        setEmail(user.email ?? '');
        setSex(user.sex ?? '');
    }, [user]);

    const resetOnboarding = () => {
        onboardingStore.setIsCompleted(false);
        navigate(ROUTES.teachersLanding);
    };

    const hasChanged = () => {
        if (name && name !== user.name) return true;
        if (sex && sex !== user.sex) return true;
        return false;
    };

    const logout = () => {
        auth.logout(navigate);
    };

    const getCurrentLangName = () =>
        availableLanguages.find((l) => l.value === i18n.language)?.name ?? '';

    const handleLanguageChange = (lang) => {
        appInterface.setLanguage(lang);
        updateUser({ lang });
    };

    return (
        <PageWrapper style={{ overflowY: 'auto', position: 'relative' }}>
            {isLoading && <Loader />}
            <div className={cl.profile}>
                <p className={cl.title}>{t('login.title')}</p>
                <div className={cl.infoCont}>
                    <div>
                        <Label text={t('email')} />
                        <Input
                            variant={'outlined'}
                            value={email}
                            disabled={true}
                        />
                    </div>
                    <div>
                        <Label text={t('users.role')} />
                        <Input
                            variant={'outlined'}
                            value={role}
                            disabled={true}
                        />
                    </div>
                </div>

                <div>
                    <Label text={t('profile.name')} />
                    <Input
                        variant={'outlined'}
                        value={name}
                        onChange={setName}
                    />
                </div>

                <div className={cl.horizontalCont}>
                    {role === Roles.Teacher && (
                        <div style={{ width: '100%' }}>
                            <Label text={t('situations.language')} />
                            <Select
                                label={getCurrentLangName()}
                                variant={'outlined'}
                                style={{ width: '100%' }}
                                value={i18n.language}
                                options={availableLanguages}
                                onChange={handleLanguageChange}
                                hideReset
                            />
                        </div>
                    )}
                </div>

                {role === Roles.Teacher && (
                    <Button
                        style={{ margin: 'auto' }}
                        variant={'purple'}
                        text={t('teacher_onboarding.reset_onboarding')}
                        onClick={resetOnboarding}
                    />
                )}
                <div className={cl.horizontalCont}>
                    {hasChanged() && (
                        <Button
                            text={t('buttons.save_changes')}
                            variant={'purple'}
                            onClick={updateUser}
                        />
                    )}
                    <Button
                        icon={<IconLogout className={cl.logoutIcon} />}
                        variant={'red'}
                        text={t('buttons.logout')}
                        onClick={logout}
                    />
                </div>
            </div>
        </PageWrapper>
    );
};

export default observer(Profile);
