import React, { useEffect, useRef } from 'react';
import cl from './Range.module.css';

const DefaultRangeColor = '#b770ff';

const Range = ({ value, min, step, max, onChange, style, color }) => {
    const rangeRef = useRef(null);
    const currentValueRef = useRef(null);

    const rangeColor = color || DefaultRangeColor;

    useEffect(() => {
        const percentage = ((value - min) / (max - min)) * 100;

        if (rangeRef.current) {
            rangeRef.current.style.background = `linear-gradient(to right, #b770ff ${percentage}%, #d3d3d3 ${percentage}%)`;
            rangeRef.current.style.background = `linear-gradient(to right, ${rangeColor} ${percentage}%, #d3d3d3 ${percentage}%)`;
        }

        if (currentValueRef.current) {
            currentValueRef.current.style.left = `calc(${percentage}%)`;
        }
    }, [value, min, max]);

    return (
        <div className={cl.rangeContainer} style={style}>
            <span className={cl.label}>{min.toString()}</span>
            <input
                ref={rangeRef}
                className={cl.range}
                type="range"
                value={value}
                onChange={onChange}
                min={min}
                step={step}
                max={max}
            />
            <div className={cl.currentValueContainer}>
                <span ref={currentValueRef} className={cl.currentValue}>
                    {value}
                </span>
            </div>

            <span className={cl.label}>{max.toString()}</span>
        </div>
    );
};

export default Range;
