import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NotifyService from '../../api/NotifyService';
import { useFetching } from '../../hooks/useFetching';
import List from '../../components/UI/List/List';
import PageWrapper from '../../components/UI/PageWrapper/PageWrapper';
import { PrimaryButton } from '../../UI';
import { NotifyCard, NotifyChip } from './components';
import { getNotifyBody } from './helpers';
import { blankNotifyId } from './data';
import cl from './Notify.module.css';

const Notify = () => {
    const { t } = useTranslation();

    const [notifications, setNotifications] = useState([]);
    const [active, setActive] = useState({});

    const [getNotifications, notificationsLoading] = useFetching(async () => {
        const { data } = await NotifyService.getNotifications();
        if (data && data.items) {
            setNotifications(data.items);
        }
    });

    const handleSave = (notifyObj) => {
        setNotifications([...notifications, notifyObj]);
        setActive({});
    };

    const handleUpdate = (notifyObj) => {
        setNotifications(
            notifications.map((n) => (n.id === notifyObj.id ? notifyObj : n))
        );
        setActive(notifyObj);
    };

    const handleClose = () => {
        setActive({});
    };

    const handleDelete = (id) => {
        setNotifications(notifications.filter((n) => n.id !== id));
        handleClose();
    };

    useEffect(() => {
        getNotifications();
    }, []);

    return (
        <PageWrapper>
            <div className={cl.notifyContainer}>
                <List
                    onScroll={() => {}}
                    isLoading={notificationsLoading}
                    style={{ padding: 0, gap: 6, overflow: 'hidden visible' }}
                >
                    {notifications.map((n) => (
                        <NotifyChip
                            key={n.id}
                            notifyObj={n}
                            isActive={n.id === active.id}
                            onClick={() => setActive(n)}
                        />
                    ))}
                    {active.id !== blankNotifyId && (
                        <PrimaryButton
                            style={{ padding: '5px 20px' }}
                            text={'+'}
                            onClick={() => {
                                setActive(getNotifyBody());
                            }}
                        />
                    )}
                </List>
                {active.id && (
                    <NotifyCard
                        notifyObj={active}
                        onSave={handleSave}
                        onClose={handleClose}
                        onChange={handleUpdate}
                        onDelete={handleDelete}
                    />
                )}
            </div>
        </PageWrapper>
    );
};

export default Notify;
