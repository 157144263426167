import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import player from '../../store/player';
import getTopCenterAndOffset from '../../utils/getTopCenterAndOffset';
import toHoursAndMinutes from '../../utils/toHoursAndMinutes';
import cl from './PlayerControls.module.css';
import { ReactComponent as IconGoNext } from '../../assets/svg/lessons-forward.svg';
import { ReactComponent as IconGoPrev } from '../../assets/svg/lessons-rewind.svg';
import { ReactComponent as IconPlay } from '../../assets/svg/lessons-play.svg';
import { ReactComponent as IconPause } from '../../assets/svg/lessons-pause.svg';
const TICKS_IN_SECOND = 10000000;

const PlayerControls = ({ handlePrevNextSentenceBtn, small }) => {
    const percent = (player.currentTime / player.trackLength) * 100;

    function eventPrevNextSentenceBtn(e) {
        if (handlePrevNextSentenceBtn) {
            handlePrevNextSentenceBtn(e);
        } else {
            const dir =
                e.currentTarget.attributes.getNamedItem('data-direction').value;
            const plusMinusTime = TICKS_IN_SECOND * 5;
            let newTime =
                dir === 'prev'
                    ? player.currentTime - plusMinusTime
                    : player.currentTime + plusMinusTime;
            if (newTime < 0) newTime = 0;
            if (newTime > player.trackLength) newTime = player.trackLength;
            player.handleChangeAudioTime(newTime, true);
        }
    }

    function handleTracklineClick(e) {
        const clientX = e.clientX;
        const rect = getTopCenterAndOffset(e.target);
        const tracklineLeftPos = rect.left;
        const tracklineWidth = rect.width;
        const clickPercent =
            ((clientX - tracklineLeftPos) / tracklineWidth) * 100;
        player.handleChangeAudioTime(
            (player.trackLength / 100) * clickPercent,
            true
        );
    }

    useEffect(() => {
        if (player.isEditMode) {
            document.addEventListener('keydown', keyDown);
        }
        return () => {
            document.removeEventListener('keydown', keyDown);
        };
    });

    function keyDown(e) {
        var evtobj = window.event ? window.event : e;
        if (
            evtobj.keyCode === 90 &&
            (evtobj.ctrlKey || evtobj.metaKey) &&
            evtobj.shiftKey
        ) {
            player.undoRedoHistory('redo');
        } else if (
            evtobj.keyCode === 90 &&
            (evtobj.ctrlKey || evtobj.metaKey)
        ) {
            player.undoRedoHistory('undo');
        }
    }

    return (
        <div style={{ padding: '7px 0px', position: 'relative' }}>
            <div className={cl.trackLineWrapper}>
                <span className={cl.timeItem}>
                    {toHoursAndMinutes(
                        Math.round(player.currentTime / TICKS_IN_SECOND)
                    )}
                </span>
                <div className={cl.trackLine} onClick={handleTracklineClick}>
                    <div
                        className={cl.trackProgress}
                        style={{ width: `${percent}%` }}
                    ></div>
                    <div
                        className={cl.dragger}
                        style={{ left: `${percent}%` }}
                    ></div>
                </div>
                <span className={cl.timeItem}>
                    {toHoursAndMinutes(player.duration)}
                </span>
            </div>

            <div
                className={cl.controlsCont}
                style={{ marginTop: !small ? null : -3 }}
            >
                <button
                    className={cl.controlBtn}
                    data-direction="prev"
                    onClick={eventPrevNextSentenceBtn}
                >
                    <IconGoPrev />
                </button>
                <button
                    className={cl.playBtn}
                    onClick={player.handlePlayPauseClick}
                >
                    {player.isPlaying ? <IconPause /> : <IconPlay />}
                </button>
                <button
                    className={cl.controlBtn}
                    data-direction="next"
                    onClick={eventPrevNextSentenceBtn}
                >
                    <IconGoNext />
                </button>
            </div>
        </div>
    );
};

export default observer(PlayerControls);
