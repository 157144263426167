import React, { useEffect, useState } from 'react';
import { ToggleButton } from '../../../../../../../../teacherComponents';
import { AdvancedQuizStatuses } from '../../../../../../data/constants';
import cl from './AdvancedQuizPreview.module.css';
import QuestionsLabel from '../QuestionsLabel/QuestionsLabel';

const AdvancedQuizPreview = ({ exerciseObj, results, showAnswers }) => {
    const { data } = exerciseObj;
    const { questions } = data;
    const [answers, setAnswers] = useState({});

    const handleAnswerClick = (questionIndex, status) => {
        const newAnswers = { ...answers };
        newAnswers[questionIndex] =
            newAnswers[questionIndex] === status ? '' : status;
        setAnswers(newAnswers);
    };

    const displayAnswers = () => {
        const correctAnswers = questions.map((q) => q.status);
        setAnswers(correctAnswers);
    };

    const resetAnswers = () => {
        if (results) return;
        setAnswers({});
    };

    const getVariant = (isCorrect) => {
        if (!results) return 'filled';
        return isCorrect ? 'correct' : 'wrong';
    };

    useEffect(() => {
        showAnswers ? displayAnswers() : resetAnswers();
    }, [showAnswers]);

    useEffect(() => {
        if (results && results.userAnswers) {
            setAnswers(results.userAnswers.map((a) => AdvancedQuizStatuses[a]));
        }
    }, [results]);

    if (!questions) return <></>;

    return (
        <>
            <QuestionsLabel
                label={results ? 'student_answers' : 'statements'}
            />
            <div className={cl.questionsCont}>
                {questions?.map((q, qIdx) => (
                    <div className={cl.question} key={q.id ?? Math.random}>
                        <p className={cl.text}>{`${qIdx + 1}. ${q.text}`}</p>
                        <div className={cl.answersCont}>
                            <ToggleButton
                                variant={getVariant(q.status === answers[qIdx])}
                                isGrid
                                nullable
                                selectedOption={answers[qIdx]}
                                withTranslations={'exercises'}
                                options={AdvancedQuizStatuses}
                                setOption={(status) => {
                                    handleAnswerClick(qIdx, status);
                                }}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default AdvancedQuizPreview;
