import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { AuthContext } from '../../App.js';
import exercisesStore from '../TeacherContent/store/exercisesStore';
import lessonsStore from '../TeacherLessons/store/lessonsStore';
import onboardingStore from '../../store/onboarding';
import studentsStore from '../TeacherStudents/store/studentsStore';
import {
    TeacherContent,
    TeacherLessons,
    Teachers,
    TeacherStudents,
    TeachersTutorial,
} from '..';
import { Chapters, Views } from '../TeacherLessons/data/constants';
import ProgressService from '../../api/ProgressService';
import SituationsService from '../../api/SituationsService.js';
import { useFetching } from '../../hooks/useFetching';
import PageWrapper from '../../components/UI/PageWrapper/PageWrapper';
import { LessonModal } from '../TeacherLessons/components';
import DemoPageLoader from '../../components/UI/DemoPageLoader/DemoPageLoader';
import { ONBOARDING_ROUTES as routes } from '../../router';
import { Button } from '../../teacherComponents';
import { OnboardingKeys } from '../../data/onboardingKeys';
import { Roles } from '../../data/common.js';
import cl from './TeachersView.module.css';

const TeachersView = ({ type }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const { auth } = useContext(AuthContext);
    const userId = auth.user?.id;
    const isGuest = auth.role === Roles.Guest;

    const { language: lang } = i18n;
    const { currentComponent, isCompleted, isDelay } = onboardingStore;

    const [getNotifications] = useFetching(async () => {
        const { data } = await ProgressService.getNotifications({
            lang,
        });
        studentsStore.setNotifications(data.items ?? []);
    });

    const [getSituationCategories] = useFetching(async () => {
        const { data: categories } =
            await SituationsService.getAvailableCategories({
                language: lang.toUpperCase(),
            });
        exercisesStore.setSituationCategoryOptions(
            categories.map((c) => ({ value: c.id, name: c.title }))
        );
    });

    const abortOnboarding = () => {
        onboardingStore.setIsCompleted(true);
        onboardingStore.setCurrentComponent('');
    };

    const renderAbortButton = () => {
        if (
            !currentComponent ||
            isCompleted ||
            currentComponent === OnboardingKeys.Start
        )
            return null;
        return (
            <div className={cl.abortButtonContainer}>
                <Button
                    variant={'dark'}
                    text={t('teacher_onboarding.abort_onboarding')}
                    onClick={abortOnboarding}
                />
            </div>
        );
    };

    const renderDelayLock = () => {
        if (!isDelay) return null;
        return (
            <div
                className={cl.delayLock}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                onTouchStart={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <div className={cl.loader}>
                    <DemoPageLoader size={12} color={'#000000'} />
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (isGuest) return;
        onboardingStore.initOnboardingStore({ routes, navigate });
    }, [isGuest]);

    useEffect(() => {
        if (isGuest) return;
        getNotifications();
        getSituationCategories();
    }, [lang, isGuest]);

    useEffect(() => {
        exercisesStore.setView(type);
    }, [type]);

    useEffect(() => {
        exercisesStore.resetState();
        lessonsStore.resetState();
        studentsStore.resetState();
    }, [userId]);

    return (
        <>
            {type === Views.Tutorial && <TeachersTutorial />}
            {!isGuest && (
                <>
                    <LessonModal />
                    <PageWrapper
                        additionalClass={
                            type === Views.Lessons ? cl.lessons : cl.hidden
                        }
                    >
                        <TeacherLessons />
                    </PageWrapper>
                    <PageWrapper
                        additionalClass={
                            type === Views.Teachers ? cl.active : cl.hidden
                        }
                    >
                        <Teachers />
                    </PageWrapper>
                    <PageWrapper
                        additionalClass={
                            type === Views.Students ? cl.active : cl.hidden
                        }
                    >
                        <TeacherStudents />
                    </PageWrapper>
                    <PageWrapper
                        additionalClass={
                            Object.values(Chapters).includes(type)
                                ? cl.active
                                : cl.hidden
                        }
                    >
                        <TeacherContent type={type} />
                    </PageWrapper>
                    {renderAbortButton()}
                    {renderDelayLock()}
                </>
            )}
        </>
    );
};

export default observer(TeachersView);
