import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Typo from 'typo-js';
import exercisesStore from '../../store/exercisesStore';
import onboardingStore from '../../../../store/onboarding';
import { useFetching } from '../../../../hooks/useFetching';
import DictionaryService from '../../../../api/DictionaryService';
import ProgressService from '../../../../api/ProgressService';
import {
    CategoryWords,
    CreateWordsCategoryModal,
    GenerateWordsCategoryModal,
    SelectDictionaryModal,
} from './components';
import {
    ChapterPlug,
    ChapterPreviewContainer,
    ChaptersList,
    ContentContainer,
} from '../../components';
import {
    ChapterModals,
    Chapters,
    SelectWordsCategoryModalType,
} from '../../../TeacherLessons/data/constants';
import { LanguageLevels } from '../../../../data/common';
import { DictionaryCodes, PendingWordsKey } from './data/constants';
import {
    OnboardingModal,
    OnboardingKeys,
} from '../../../../teacherComponents/Onboarding';

const DictionaryView = ({ isModal, alreadyAdded, onSelect }) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;
    const intervalRef = useRef();

    const { difficulty, chaptersModalType, dictionarySearch, unassigned } =
        exercisesStore;

    const isOnboarding = onboardingStore.isOnboarding(
        OnboardingKeys.DictionaryAwaitSelect
    );

    const [dictionary, setDictionary] = useState();

    const [categories, setCategories] = useState([]);
    const [filteredCategories, setFilteredCategories] = useState([]);

    const [currentCategory, setCurrentCategory] = useState();
    const [currentWord, setCurrentWord] = useState();

    const [addedWord, setAddedWord] = useState('');
    const [pendingWords, setPendingWords] = useState([]);

    const [isFilter, setIsFilter] = useState(false);

    const [getCategories, isLoading] = useFetching(async () => {
        const { data } = await ProgressService.getUserCategories({
            unassigned,
        });

        if (!data?.items) {
            setCategories([]);
            return;
        }

        const currentLangCategories = data.items.filter((c) => c.lang === lang);

        const categoriesWithWords = await Promise.all(
            currentLangCategories.map(async (c) => {
                const { data: wordsData } =
                    await ProgressService.getCategoryWords({
                        categoryId: c.id,
                        lang,
                    });
                return {
                    ...c,
                    words: wordsData.items,
                    wordsCount: wordsData.items?.length ?? '?',
                };
            })
        );

        const withoutExisting = alreadyAdded?.length
            ? categoriesWithWords.filter(
                  (e) => !alreadyAdded.some((a) => a.id === e.id)
              )
            : categoriesWithWords;

        setCategories(withoutExisting || []);
    });

    const [getCurrentCategory] = useFetching(async () => {
        const { data } = await ProgressService.getCategoryWords({
            categoryId: currentCategory.id,
            lang,
        });
        setCurrentCategory({ ...currentCategory, words: data.words });
        if (!currentWord) setCurrentWord(data.words[0] || null);
    });

    const [addCategory, addLoading] = useFetching(async (category) => {
        const { id } = category;
        await ProgressService.cloneWordsCategory({
            id,
            lang,
        });
        setCategories([category, ...categories]);
        setCurrentCategory(category);
        if (isOnboarding) {
            onboardingStore.moveToNextComponent();
        }
    });

    const [processWord] = useFetching(async ({ word, categoryId }, isNew) => {
        if (!word) return;
        if (isNew) setAddedWord('');
        const {
            data: wordObj,
            status,
            errorCode,
        } = await DictionaryService.getWordByText({
            word,
            sourceLang: lang,
            targetLang: lang,
        });

        if (status === DictionaryCodes.Ok) {
            const wordId = wordObj.data[0].id;
            await keepWord({ word, wordId, categoryId });
        } else {
            if (errorCode === DictionaryCodes.InvalidWord) {
                addPendingWord({ word, categoryId, invalid: true });
                return;
            }

            addPendingWord({ word, categoryId });
            await addWord({ word, categoryId });
        }
    });

    const [addWord] = useFetching(async ({ word }) => {
        await DictionaryService.addWord({
            word,
            sourceLang: lang,
            // since sourceLang = targetLang results in a 400 Bad Request response
            targetLang: lang === 'en' ? 'de' : 'en',
        });
    });

    const [keepWord] = useFetching(async ({ word, wordId, categoryId }) => {
        await ProgressService.addFavoriteWord({
            id: wordId,
            word,
            lang,
        });

        try {
            await ProgressService.addCategoryWord({
                categoryId,
                word,
                lang,
            });
        } catch (e) {
            console.error(e);
        }

        syncAddWord({ word, categoryId });
    });

    const [deleteActiveWord] = useFetching(async () => {
        if (!currentWord?.word) return;

        const { word } = currentWord;

        await ProgressService.deleteCategoryWord({
            categoryId: currentCategory.id,
            word,
        });

        const newWords = currentCategory.words.filter((w) => w.word !== word);

        setCurrentWord();
        handleCategoryUpdate({
            ...currentCategory,
            words: newWords,
        });
    });

    const handleGeneratedCategoryAdd = (addedCategory, generatedWords) => {
        handleCategoryAdd(addedCategory);
        for (const word of generatedWords) {
            addPendingWord({ word, categoryId: addedCategory.id });
        }
    };

    const handleCategoryChange = (category) => {
        setCurrentCategory(null);
        setTimeout(() => {
            setCurrentCategory(category);
        });
    };

    const handleCategorySelect = onSelect
        ? () => {
              if (!currentCategory) return;
              const { id, title } = currentCategory;
              onSelect({ id, title });
          }
        : null;

    const handleCategoryAdd = (addedCategory) => {
        const words = [];

        setCategories([{ ...addedCategory, words }, ...categories]);
        setCurrentCategory({ ...addedCategory, words });
    };

    const handleCategoryDelete = (deletedId) => {
        if (currentCategory.id === deletedId) {
            setCurrentCategory();
            setCurrentWord();
        }
        setCategories(categories.filter((c) => c.id !== deletedId));
    };

    const handleCategoryUpdate = (updatedCategory) => {
        setCategories(
            categories.map((c) =>
                c.id === updatedCategory.id ? updatedCategory : c
            )
        );
        if (currentCategory.id === updatedCategory.id) {
            setCurrentCategory(updatedCategory);
        }
    };

    const getPendingWords = () => {
        const savedData = localStorage.getItem(PendingWordsKey);
        const pendingWords = savedData ? JSON.parse(savedData) : [];
        setPendingWords(pendingWords);
    };

    const getCurrentPendingWords = () =>
        pendingWords.filter((w) => w.categoryId === currentCategory.id);

    const addPendingWord = ({ word, categoryId, invalid }) => {
        if (!word) return;

        setPendingWords((prevPendingWords) => {
            const filtered = prevPendingWords.filter(
                (w) => !(w.word === word && w.categoryId === categoryId)
            );

            const newPendingWords = [
                ...filtered,
                { word, categoryId, invalid },
            ];

            localStorage.setItem(
                PendingWordsKey,
                JSON.stringify(newPendingWords)
            );
            return newPendingWords;
        });
    };

    const deletePendingWord = ({ word, categoryId }) => {
        const newPendingWords = pendingWords.filter(
            (w) => !(w.word === word && w.categoryId === categoryId)
        );
        setPendingWords(newPendingWords);
        localStorage.setItem(PendingWordsKey, JSON.stringify(newPendingWords));
    };

    const syncAddWord = ({ word, categoryId }) => {
        const category = categories.find((c) => c.id === categoryId);
        const addedWord = { categoryId, word, lang };

        handleCategoryUpdate({
            ...category,
            wordsCount: category.wordsCount ? category.wordsCount + 1 : 1,
            words: [...category.words, addedWord],
        });

        if (currentCategory.id === categoryId) {
            setCurrentWord(addedWord);
        }

        deletePendingWord({ word, categoryId });
    };

    const deleteWord = (pendingWord) => {
        pendingWord ? deletePendingWord(pendingWord) : deleteActiveWord();
    };

    const processPendingWords = () => {
        const active = pendingWords.filter((w) => !w?.invalid);
        for (const word of active) {
            processWord(word, false);
        }
    };

    useEffect(() => {
        getPendingWords();
    }, [unassigned]);

    useEffect(() => {
        getCategories();
    }, [unassigned]);

    useEffect(() => {
        getCurrentCategory();
        setAddedWord('');
    }, [currentCategory?.id]);

    useEffect(() => {
        setAddedWord('');
    }, [currentWord]);

    useEffect(() => {
        if (filteredCategories.some((c) => c.id === currentCategory?.id))
            return;
        setCurrentCategory(filteredCategories[0]);

        if (!filteredCategories[0] || !filteredCategories[0].words) return;
        setCurrentWord(filteredCategories[0].words[0]);
    }, [filteredCategories]);

    useEffect(() => {
        const filteredByCategories = categories.filter((c) => {
            const matchesSearch = dictionarySearch
                ? c.title.toLowerCase().includes(dictionarySearch.toLowerCase())
                : true;

            const matchesDifficulty = difficulty
                ? c.level === LanguageLevels.indexOf(difficulty)
                : true;

            return matchesSearch && matchesDifficulty;
        });

        setFilteredCategories(filteredByCategories);
        setIsFilter(difficulty || dictionarySearch);
    }, [dictionarySearch, difficulty, categories]);

    useEffect(() => {
        clearInterval(intervalRef.current);
        const active = pendingWords.filter((w) => !w?.invalid);
        if (active.length) {
            intervalRef.current = setInterval(() => {
                processPendingWords();
            }, 3000);
        }
        return () => clearInterval(intervalRef.current);
    }, [pendingWords, lang]);

    useEffect(() => {
        const typoDictionary = new Typo('en_US', false, false, {
            dictionaryPath: '/dictionaries',
        });
        setDictionary(typoDictionary);
    }, [lang]);

    useEffect(() => {
        setCurrentWord(
            currentCategory?.words ? currentCategory?.words[0] : null
        );
    }, [currentCategory?.id]);

    return (
        <ContentContainer view={isModal ? '' : Chapters.Dictionary}>
            <ChaptersList
                type={Chapters.Dictionary}
                isLoading={isLoading}
                chapters={filteredCategories}
                current={currentCategory}
                onDelete={handleCategoryDelete}
                setCurrent={handleCategoryChange}
                onSelect={handleCategorySelect}
                isFilter={isFilter}
            />
            {currentCategory ? (
                <ChapterPreviewContainer>
                    <CategoryWords
                        dictionary={dictionary}
                        category={currentCategory}
                        currentWord={currentWord}
                        setCurrentWord={setCurrentWord}
                        addedWord={addedWord}
                        setAddedWord={setAddedWord}
                        pendingWords={getCurrentPendingWords()}
                        onAdd={processWord}
                        onDelete={deleteWord}
                        onUpdate={handleCategoryUpdate}
                    />
                </ChapterPreviewContainer>
            ) : (
                <ChapterPlug />
            )}
            <GenerateWordsCategoryModal
                lang={lang}
                visible={
                    chaptersModalType === ChapterModals[Chapters.Dictionary]
                }
                setVisible={exercisesStore.setChaptersModalType}
                onAdd={handleGeneratedCategoryAdd}
            />
            <CreateWordsCategoryModal
                lang={lang}
                visible={chaptersModalType === SelectWordsCategoryModalType}
                setVisible={exercisesStore.setChaptersModalType}
                onAdd={handleCategoryAdd}
            />
            <SelectDictionaryModal
                visible={chaptersModalType === Chapters.Dictionary}
                setVisible={exercisesStore.setChaptersModalType}
                onClick={addCategory}
                alreadyAdded={categories}
                isOnboarding={isOnboarding}
            />
            <OnboardingModal
                onboardingKey={onboardingStore.isOnboarding(
                    OnboardingKeys.DictionaryInfo
                )}
                title={t('teacher_onboarding.dictionary_info')}
                subtitle={t('teacher_onboarding.dictionary_info_subtitle')}
                delay={500}
            />
        </ContentContainer>
    );
};

export default observer(DictionaryView);
