import axios from 'axios';
import { LanguageLevels } from '../data/common';
const OLD_URL = 'user-progress';
const URL = 'v1/user-progress';

export default class ProgressService {
    static async addTrack({ trackId, language }) {
        const response = await axios.post(`${URL}/tracks/${trackId}`, {
            language,
        });
        return response;
    }

    static async deleteTrack({ id }) {
        const response = await axios.delete(`${URL}/tracks/${id}`);
        return response;
    }

    static async addSituation({ situationId, language, gender }) {
        const response = await axios.post(`${URL}/situations/${situationId}`, {
            language,
            listeningComplete: 0,
            puzzleCompleted: 0,
            speakingCompleted: 0,
            translationCompleted: 0,
            targetLanguage: language,
            selectedGender: gender ?? 'Male',
            favourite: true,
        });
        return response;
    }

    static async deleteSituation({ id, lang }) {
        const response = await axios.delete(
            `${URL}/situations/${id}?lang=${lang}`
        );
        return response;
    }

    static async getSituations({ lang, unassigned }) {
        const queryParams = [`lang=${lang}`];

        if (unassigned) {
            queryParams.push(`unassigned=${unassigned}`);
        }

        const response = await axios.get(
            `${URL}/situations/get-list?${queryParams.join('&')}`
        );
        return response;
    }

    static async assignSituation({
        studentId,
        situationId,
        lang,
        level,
        title,
        picture,
        categoryTitle,
        info,
    }) {
        const response = await axios.post(
            `${URL}/teacher/assign-situation/${studentId}`,
            {
                data: {
                    id: situationId,
                    situationJson: JSON.stringify({
                        id: situationId,
                        title,
                        categoryTitle,
                        picture,
                        info,
                        level,
                    }),
                    listeningComplete: 0,
                    puzzleCompleted: 0,
                    speakingCompleted: 0,
                    translationCompleted: 0,
                    isAdded: true,
                    level: LanguageLevels.indexOf(level),
                },
                targetLanguage: lang,
            }
        );
        return response;
    }

    static async getExercises({ lang, unassigned }) {
        const queryParams = [`lang=${lang}`];

        if (unassigned) {
            queryParams.push(`unassigned=${unassigned}`);
        }

        const response = await axios.get(
            `${URL}/exercises?${queryParams.join('&')}`
        );
        return response;
    }

    static async getWordsCategories({ searchString, level, lang }) {
        const queryParams = [`?lang=${lang}`];

        if (searchString) {
            queryParams.push(`searchString=${searchString}`);
        }

        if (typeof level === 'number') {
            queryParams.push(`level=${level}`);
        }

        const response = await axios.get(
            `${URL}/words/category${queryParams.join('&')}`
        );
        return response;
    }

    static async getUserCategories({ unassigned }) {
        let unassignedStr =
            unassigned !== undefined ? `?unassigned=${unassigned}` : '';

        const response = await axios.get(
            `${URL}/words/user-category${unassignedStr}`
        );
        return response;
    }

    static async createWordsCategory({ title, lang, level }) {
        const response = await axios.post(`${URL}/words/category`, {
            title,
            lang,
            level,
        });
        return response;
    }

    static async cloneWordsCategory({ id, lang }) {
        const response = await axios.post(
            `${URL}/words/category/${id}/clone?lang=${lang}`
        );
        return response;
    }

    static async updateWordsCategory({ id, title, level }) {
        const body = {};

        if (title) body.title = title;
        if (level) body.level = level;

        const response = await axios.patch(`${URL}/words/category/${id}`, body);
        return response;
    }

    static async deleteWordsCategory({ id }) {
        const response = await axios.delete(`${URL}/words/category/${id}`);
        return response;
    }

    static async addCategoryWord({ categoryId, word, lang }) {
        const response = await axios.post(
            `${URL}/words/category/${categoryId}/word`,
            {
                word,
                lang,
            }
        );
        return response;
    }

    static async addFavoriteWord({ id, word, lang }) {
        const response = await axios.post(`${URL}/words/${id}`, {
            word,
            lang,
        });
        return response;
    }

    static async deleteCategoryWord({ categoryId, word }) {
        const response = await axios.delete(
            `${URL}/words/category/${categoryId}/${word}`
        );
        return response;
    }

    static async getCategoryWords({ categoryId, lang }) {
        const response = await axios.get(
            `${URL}/words/category/${categoryId}/word?lang=${lang}`
        );
        return response;
    }

    static async assignWord({ studentId, wordId }) {
        const response = await axios.post(
            `${URL}/teacher/assign-word/${studentId}/${wordId}`
        );
        return response;
    }

    static async assignTrack({ studentId, trackId, lang }) {
        const response = await axios.post(
            `${URL}/teacher/assign-track/${studentId}/${trackId}?language=${lang}`
        );
        return response;
    }

    static async assignExercise({ studentId, exerciseId }) {
        const response = await axios.post(
            `${URL}/teacher/assign-exercise/${studentId}/${exerciseId}`
        );
        return response;
    }

    static async assignWordCategory({ studentId, categoryId, lang }) {
        const response = await axios.post(
            `${URL}/teacher/assign-word-category/${studentId}/${categoryId}?lang=${lang}`
        );
        return response;
    }

    static async assignLesson({ studentId, lessonId }) {
        const response = await axios.post(
            `${URL}/teacher/assign-lesson/${studentId}/${lessonId}`
        );
        return response;
    }

    static async sendLessonData({ studentId, lessonId }) {
        const response = await axios.post(
            `${URL}/teacher/send-lesson-data/${studentId}/${lessonId}`
        );
        return response;
    }

    static async evaluateLesson({ studentId, lessonId, status }) {
        const response = await axios.patch(
            `${URL}/lessons/update-status/${lessonId}?userId=${studentId}&status=${status}`,
            {
                status,
            }
        );
        return response;
    }

    static async evaluateExercise({ studentId, exerciseId, status, comment }) {
        const response = await axios.patch(
            `${URL}/teacher/evaluate-exercise/${studentId}/${exerciseId}`,
            {
                status,
                comment,
            }
        );
        return response;
    }

    static async getStudentSituations({ userId, lang }) {
        const response = await axios.get(
            `${URL}/teacher/${userId}/situations?lang=${lang}`
        );
        return response;
    }

    static async getStudentSituationsOld({ userId, lang }) {
        const response = await axios.get(
            `${OLD_URL}/situations/get-list?userId=${userId}&targetLanguage=${lang}`
        );
        return response;
    }

    static async getStudentWordsOld({ userId, lang }) {
        const response = await axios.get(
            `${OLD_URL}/words/get-list?userId=${userId}&targetLanguage=${lang}`
        );
        return response;
    }

    static async getStudentWords({ userId, lang }) {
        const response = await axios.get(
            `${URL}/teacher/${userId}/words?lang=${lang}`
        );
        return response;
    }

    static async getStudentDictionaries({ userId }) {
        const response = await axios.get(
            `${URL}/teacher/${userId}/word-categories`
        );
        return response;
    }

    static async getStudentTracks({ userId, lang }) {
        const response = await axios.get(
            `${URL}/teacher/${userId}/tracks?lang=${lang}`
        );
        return response;
    }

    static async getStudentExercises({ userId, lang }) {
        const response = await axios.get(
            `${URL}/teacher/${userId}/exercises?lang=${lang}`
        );
        return response;
    }

    static async getStudentLessons({ userId, lang }) {
        const response = await axios.get(
            `${URL}/teacher/${userId}/lessons?lang=${lang}`
        );
        return response;
    }

    static async getNotifications({ lang }) {
        const response = await axios.get(
            `${URL}/teacher/exercises-notifications?lang=${lang}`
        );
        return response;
    }

    static async deleteLesson({ id }) {
        const response = await axios.delete(`${URL}/lessons/${id}`);
        return response;
    }
}
