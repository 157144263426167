import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import exercisesStore from '../../../../store/exercisesStore';
import { useFetching } from '../../../../../../hooks/useFetching';
import TrackService from '../../../../../../api/TrackService';
import {
    Button,
    CloseButton,
    Input,
    Label,
    Select,
    ToggleButton,
} from '../../../../../../teacherComponents';
import Modal from '../../../../../../components/UI/Modal/Modal';
import { LEVELS } from '../../../../../SituationsAdministration/data/constants';
import cl from './AddVideoModal.module.css';
import { GenerateChapterModal } from '../../../../components';

const AddVideoModal = ({ lang, visible, setVisible, onAdd }) => {
    const { t } = useTranslation();

    const { categoryOptions } = exercisesStore;

    const [formErrors, setFormErrors] = useState({});

    const [level, setLevel] = useState();
    const [categoryId, setCategoryId] = useState();
    const [url, setUrl] = useState('');

    const [addTrack, isLoading, error, resetError] = useFetching(async () => {
        const { data } = await TrackService.addTrackByUrl({
            url,
            lang,
        });
        const trackId = data.id;

        setTimeout(() => {
            onAdd(trackId);
            setVisible(false);
        }, 200);
    });

    const isAddDisabled = () => {
        return false;
    };

    const handleTrackAdd = () => {
        setFormErrors({});

        setTimeout(() => {
            const errors = {
                level: !level,
                categoryId: !categoryId,
                url: !url,
            };
            setFormErrors(errors);

            if (Object.values(errors).some((e) => e)) return;

            addTrack();
        });
    };

    useEffect(() => {
        resetError();
        setFormErrors({});
    }, [visible, level, categoryId, url]);

    useEffect(() => {
        setLevel();
        setCategoryId();
        setUrl('');
    }, [visible]);

    return (
        <GenerateChapterModal
            visible={visible}
            setVisible={setVisible}
            title={t('exercises.new_track')}
            subtitle={t('exercises.new_track_desc')}
        >
            <div>
                <Label
                    text={t('add_track_input.link')}
                    isError={formErrors['url']}
                />
                <Input
                    value={url}
                    onChange={setUrl}
                    placeholder={'https://www.youtube.com/watch?v=example_id'}
                    variant={'outlinedSmall'}
                />
            </div>
            <div className={cl.horizontalContainer}>
                <div>
                    <Label
                        text={t('situations.level')}
                        isError={formErrors['level']}
                    />
                    <ToggleButton
                        variant={formErrors['level'] ? 'error' : 'outlined'}
                        isGrid
                        options={LEVELS}
                        selectedOption={level}
                        setOption={setLevel}
                    />
                </div>
                <div style={{ width: '100%' }}>
                    <Label
                        text={t('tags_cont.choose_category')}
                        isError={formErrors['categoryId']}
                    />
                    <Select
                        variant={'grey'}
                        label={t('tags_cont.choose_category')}
                        value={categoryId}
                        options={categoryOptions}
                        onChange={setCategoryId}
                        style={{ width: '100%' }}
                        height={150}
                        ignoreOverflow
                    />
                </div>
            </div>
            {error && (
                <p className={cl.error}>
                    {t('dictionary_administration.error')}
                </p>
            )}
            <Button
                style={{ width: '100%' }}
                variant={'purple'}
                text={t('buttons.add')}
                onClick={handleTrackAdd}
                isLoading={isLoading}
                isDisabled={isAddDisabled()}
            />
        </GenerateChapterModal>
    );
};

export default observer(AddVideoModal);
