import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InterfaceContext } from '../../../../App';
import { useFetching } from '../../../../hooks/useFetching';
import { PrimaryButton } from '../../../../UI';
import { Select, ToggleButton, Toolbar } from '../../../../teacherComponents';
import TrackCard from '../TrackCard/TrackCard';
import Modal from '../../../../components/UI/Modal/Modal';
import TrackService from '../../../../api/TrackService';
import { LanguageLevels } from '../../../../data/common';
import cl from './PriorityTracks.module.css';

const PriorityTracks = () => {
    const { t } = useTranslation();

    const { appInterface } = useContext(InterfaceContext);
    const { languages } = appInterface;

    const LANGUAGE_OPTIONS = [...languages];

    const [visible, setVisible] = useState(false);
    const [lang, setLang] = useState('en');
    const [level, setLevel] = useState('A2');
    const [tracks, setTracks] = useState([]);

    const [getPriorityTracks, isLoading] = useFetching(async () => {
        const { data } = await TrackService.getPriorityTracks({
            lang,
            difficulty: LanguageLevels.indexOf(level),
        });
        setTracks(data.items);
    });

    useEffect(() => {
        setLevel('A2');
        setLang('en');
        setTracks([]);
        if (visible) {
            getPriorityTracks();
        }
    }, [visible]);

    useEffect(() => {
        if (visible) {
            getPriorityTracks();
        }
    }, [lang, level]);

    return (
        <>
            <PrimaryButton
                text={t('library.priority_tracks')}
                variant={'magenta'}
                onClick={() => setVisible(true)}
            />
            <Modal
                visible={visible}
                setVisible={setVisible}
                className={cl.priorityTracksModal}
            >
                <Toolbar>
                    <p>{t('library.priority_tracks')}</p>
                    <Select
                        height={300}
                        value={lang}
                        options={LANGUAGE_OPTIONS}
                        onChange={setLang}
                        variant={'white'}
                        ignoreOverflow
                    />
                    <ToggleButton
                        style={{ marginLeft: 0, marginRight: 'auto' }}
                        selectedOption={level}
                        options={LanguageLevels}
                        setOption={setLevel}
                    />
                </Toolbar>
                <div className={cl.tracksList}>
                    {tracks.length !== 0 ? (
                        <div className={cl.listInner}>
                            {tracks.map((t) => (
                                <TrackCard id={t.id} trackData={t} />
                            ))}
                        </div>
                    ) : (
                        <p className={cl.emptyAlert}>
                            {t('errors.tracks_filter')}
                        </p>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default PriorityTracks;
