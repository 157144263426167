import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import onboardingStore from '../../store/onboarding';
import studentsStore from './store/studentsStore';
import { ContentContainer } from '../TeacherContent/components';
import { useFetching } from '../../hooks/useFetching';
import UserService from '../../api/UserService';
import {
    AddStudentModal,
    StudentCard,
    StudentKanban,
    StudentLessonsSearchBar,
    StudentSearchBar,
} from './components';
import { LanguageLevels } from '../../data/common';
import { Loader } from '../../UI';
import { Faces } from './data/faces';
import { ViewTitle } from '../../teacherComponents';
import {
    OnboardingWrapper,
    OnboardingKeys,
    BubblePositions,
    OnboardingModal,
} from '../../teacherComponents/Onboarding';
import { MockOnboardingStudentId } from './data';
import cl from './TeacherStudents.module.css';

const TeacherStudents = () => {
    const { t, i18n } = useTranslation();

    const { currentStudent, difficulty, studentSearch, notifications } =
        studentsStore;
    const { id: studentId } = currentStudent;

    const { temporaryStudent, isCompleted } = onboardingStore;

    const [students, setStudents] = useState([]);
    const [filtered, setFiltered] = useState([]);

    const [getStudents, studentsLoading] = useFetching(async () => {
        const { data } = await UserService.getStudents();

        const options = data.items.map((s, i) => ({
            id: s.id,
            name: s.nickname || s.name || t('exercises.unnamed_student'),
            level: LanguageLevels[s.teacherSetLevel ?? s.level] ?? '??',
            nativeLang: s.nativeLang ?? '??',
            targetLang: s.lang ?? '??',
            profilePic: Faces[i],
        }));

        setStudents(options.filter((o) => o.targetLang === i18n.language));
        studentsStore.setCurrentStudent(options[0] ?? {});
    });

    const [removeStudent] = useFetching(async () => {
        if (!studentId) return;

        await UserService.removeStudent({ id: studentId });
        setStudents(students.filter((s) => s.id !== studentId));
        studentsStore.setCurrentStudent({});
    });

    const changeStudent = (newStudent) => {
        setStudents(
            students.map((s) => (s.id === newStudent.id ? newStudent : s))
        );
        if (currentStudent.id === newStudent.id) {
            studentsStore.setCurrentStudent(newStudent);
        }
    };

    const handleOnboardingEnd = () => {
        onboardingStore.setIsCompleted(true);
        onboardingStore.setCurrentComponent('');
    };

    const setTemporaryStudent = () => {
        const studentObj = {
            id: MockOnboardingStudentId,
            name: t('teacher_onboarding.temporary_student_name'),
            level: 'A1',
            nativeLang: i18n.language === 'de' ? 'en' : 'de',
            targetLang: i18n.language,
            profilePic: Faces[1],
        };
        setStudents([studentObj]);
        setFiltered([studentObj]);
        studentsStore.setCurrentStudent(studentObj);
    };

    const getStudentNotification = (id) => {
        return notifications.find((n) => n.studentId === id);
    };

    const handleStudentSelect = (student) => {
        studentsStore.setCurrentStudent(student);
    };

    useEffect(() => {
        if (isCompleted) {
            onboardingStore.setTemporaryStudent(false);
            getStudents();
        }
    }, [isCompleted]);

    useEffect(() => {
        getStudents();
    }, []);

    useEffect(() => {
        if (temporaryStudent) {
            setTemporaryStudent();
        }
    }, [temporaryStudent]);

    useEffect(() => {
        setFiltered(
            students.filter((s) => {
                const matchesName = studentSearch
                    ? s.name.toLowerCase().includes(studentSearch.toLowerCase())
                    : true;

                const matchesDifficulty = difficulty
                    ? s.level === difficulty
                    : true;

                return matchesName && matchesDifficulty;
            })
        );
    }, [difficulty, studentSearch, students]);

    useEffect(() => {
        if (!filtered.some((s) => currentStudent?.id === s.id)) {
            studentsStore.setCurrentStudent(filtered[0] ?? {});
        }
    }, [filtered]);

    return (
        <>
            <ViewTitle text={t('navbar.students')} />
            <ContentContainer>
                <div className={cl.aside}>
                    <StudentSearchBar />
                    <div className={cl.list}>
                        {temporaryStudent && filtered.length !== 0 ? (
                            <OnboardingWrapper
                                onboardingKey={onboardingStore.isOnboarding(
                                    OnboardingKeys.NewStudent
                                )}
                                title={t(
                                    'teacher_onboarding.temporary_student'
                                )}
                                subtitle={t(
                                    'teacher_onboarding.temporary_student_subtitle'
                                )}
                                delay={500}
                                showButton
                                bubblePosition={{
                                    x: BubblePositions.x.Center,
                                    y: BubblePositions.y.Bottom,
                                }}
                                style={{ overflow: 'hidden' }}
                                bubbleAutoWidth
                            >
                                <div>
                                    <StudentCard
                                        student={filtered[0]}
                                        isActive={filtered[0]?.id === studentId}
                                        notifications={[]}
                                        setCurrent={handleStudentSelect}
                                    />
                                </div>
                            </OnboardingWrapper>
                        ) : studentsLoading ? (
                            <Loader style={{ margin: 'auto' }} />
                        ) : (
                            <>
                                {filtered
                                    .sort((a, b) =>
                                        a.name.localeCompare(b.name)
                                    )
                                    .map((s) => (
                                        <StudentCard
                                            key={s.id}
                                            student={s}
                                            isActive={s.id === studentId}
                                            notifications={getStudentNotification(
                                                s.id
                                            )}
                                            setCurrent={handleStudentSelect}
                                            onDelete={removeStudent}
                                            onChange={changeStudent}
                                        />
                                    ))}
                                <AddStudentModal />
                            </>
                        )}
                    </div>
                </div>
                <div className={cl.mainPart}>
                    <StudentLessonsSearchBar />
                    <StudentKanban />
                </div>
            </ContentContainer>

            <OnboardingModal
                title={t('teacher_onboarding.start_title')}
                subtitle={t('teacher_onboarding.start_subtitle')}
                onboardingKey={onboardingStore.isOnboarding(
                    OnboardingKeys.Confirmation
                )}
                delay={100}
            />

            <OnboardingModal
                onboardingKey={onboardingStore.isOnboarding(
                    OnboardingKeys.StudentsDone
                )}
                title={t('teacher_onboarding.students_done_title')}
                subtitle={t('teacher_onboarding.students_done_subtitle')}
                delay={1000}
            />
            <OnboardingModal
                onboardingKey={onboardingStore.isOnboarding(
                    OnboardingKeys.OnboardingDone
                )}
                title={t('teacher_onboarding.onboarding_done')}
                subtitle={t('teacher_onboarding.onboarding_done_subtitle')}
                delay={1000}
                onClose={handleOnboardingEnd}
            />
        </>
    );
};

export default observer(TeacherStudents);
