import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { v4 as uuid } from 'uuid';
import AddButton from '../../../../../../../../components/UI/AddButton/AddButton';
import AdjustableTextarea from '../../../../../../../../components/UI/AdjustableTextarea/AdjustableTextarea';
import InputLabel from '../../../../../../../../components/UI/InputLabel/InputLabel';
import { QuestionsLabel } from '../../../Preview/components';
import cl from './QuestionsEditor.module.css';

const QuestionsEditor = ({ exerciseData, disableSave, onChange }) => {
    const { t } = useTranslation();

    const { questions = [] } = exerciseData;
    const [isAddDisabled, setIsAddDisabled] = useState(false);

    const handleAddSentence = () => {
        onChange({
            questions: [...questions, { id: uuid(), question: '' }],
        });
    };

    const handleUpdateSentence = (id, question) => {
        onChange({
            questions: questions.map((s) =>
                s.id === id ? { ...s, question } : s
            ),
        });
    };

    const deleteEducatorSentence = (id) => {
        const newQuestions = questions.filter((s) => s.id !== id);
        if (newQuestions.length === 0) {
            newQuestions.push({ id: uuid(), question: '' });
        }
        onChange({
            questions: newQuestions,
        });
    };

    useEffect(() => {
        if (!questions.length) {
            setIsAddDisabled(false);
            return;
        }
        if (questions[questions.length - 1]) {
            setIsAddDisabled(!questions[questions.length - 1].question.length);
        }
    }, [questions]);

    useEffect(() => {
        disableSave(isAddDisabled);
    }, [isAddDisabled]);

    return (
        <div className={cl.wordsOrderEditor}>
            {questions.length ? (
                <div className={cl.questions}>
                    <QuestionsLabel label={'edit_questions'} />
                    {questions.map((s, i) => (
                        <div className={cl.questionCont} key={s.id}>
                            <p className={cl.questionNumber}>{`${i + 1}.`}</p>
                            <AdjustableTextarea
                                className={cl.question}
                                placeholder={t('exercises.add_question')}
                                value={s.question}
                                onChange={(question) =>
                                    handleUpdateSentence(s.id, question)
                                }
                                onDelete={() => deleteEducatorSentence(s.id)}
                                isFocus={!s.question?.length}
                                mode={'sentence'}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <InputLabel text={t('exercises.no_questions')} />
            )}
            <AddButton
                text={t(
                    questions.length
                        ? 'exercises.new_question'
                        : 'exercises.first_question'
                )}
                onClick={handleAddSentence}
                isDisabled={isAddDisabled}
            />
        </div>
    );
};

export default observer(QuestionsEditor);
