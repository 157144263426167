import axios from 'axios';

const URL = 'v1/notify-admin';

export default class NotifyService {
    static async getNotifications() {
        const response = await axios.get(`${URL}/`);
        return response;
    }

    static async createNotification({ time, title, text, localizations }) {
        const response = await axios.post(`${URL}/`, {
            time,
            title,
            text,
            localizations,
        });
        return response;
    }

    static async updateNotification(id, { time, title, text, localizations }) {
        const response = await axios.patch(`${URL}/${id}`, {
            time,
            title,
            text,
            localizations,
        });
        return response;
    }

    static async deleteNotification(id) {
        const response = await axios.delete(`${URL}/${id}`);
        return response;
    }
}
