import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useFetching } from '../../../../hooks/useFetching';
import CategoryService from '../../../../api/CategoryService';
import exercisesStore from '../../store/exercisesStore';
import {
    Checkbox,
    Input,
    Select,
    Separator,
    ToggleButton,
    Toolbar,
} from '../../../../teacherComponents';
import { LanguageLevels } from '../../../../data/common';
import { GrammarExerciseTypes, MediaExerciseTypes } from '../../data/constants';
import { Chapters } from '../../../TeacherLessons/data/constants';

const ContentSearchBar = ({ currentView }) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;

    const {
        unassigned,
        difficulty,
        type,
        view,
        existingCategoryOptions,
        existingSituationCategoryOptions,
    } = exercisesStore;

    const exercisesTypeOptions = [
        ...Object.values(GrammarExerciseTypes),
        ...Object.values(MediaExerciseTypes),
    ].map((type) => ({ value: type, name: t(`exercises.${type}`) }));

    const [getTrackCategories] = useFetching(async () => {
        const { data } = await CategoryService.getCategories({
            lang,
        });
        exercisesStore.setCategoryOptions(
            data.map((c) => ({ value: c.id, name: c.translation || c.title }))
        );
    });

    const getSearchString = () => {
        switch (currentView ?? view) {
            case Chapters.Track: {
                return exercisesStore.trackSearch;
            }
            case Chapters.Situation: {
                return exercisesStore.situationSearch;
            }
            case Chapters.Exercise: {
                return exercisesStore.exerciseSearch;
            }
            case Chapters.Dictionary: {
                return exercisesStore.dictionarySearch;
            }
            default:
                return '';
        }
    };

    useEffect(() => {
        getTrackCategories();
    }, [lang]);

    useEffect(() => {
        exercisesStore.setDefaultSearchBarParams();
    }, [view]);

    return (
        <Toolbar>
            <ToggleButton
                nullable
                options={LanguageLevels}
                selectedOption={difficulty}
                setOption={exercisesStore.setDifficulty}
                variant={'round'}
            />
            {view === Chapters.Exercise && (
                <Select
                    label={t('exercises.type')}
                    style={{ minWidth: 220 }}
                    variant={'white'}
                    value={type}
                    onChange={exercisesStore.setType}
                    options={exercisesTypeOptions}
                />
            )}
            {view === Chapters.Track && (
                <Select
                    label={t('tags_cont.choose_category')}
                    style={{ minWidth: 220 }}
                    variant={'white'}
                    value={exercisesStore.trackCategory}
                    onChange={exercisesStore.setTrackCategory}
                    options={existingCategoryOptions}
                />
            )}
            {view === Chapters.Situation && (
                <Select
                    label={t('tags_cont.choose_category')}
                    style={{ minWidth: 220 }}
                    variant={'white'}
                    value={exercisesStore.situationCategory}
                    onChange={exercisesStore.setSituaitonCategory}
                    options={existingSituationCategoryOptions}
                />
            )}
            <Checkbox
                label={t('exercises.not_used_chapter')}
                value={unassigned}
                onChange={exercisesStore.setUnassigned}
            />
            <Separator />
            <Input
                search
                placeholder={t('library.search')}
                value={getSearchString()}
                onChange={(val) =>
                    exercisesStore.setSearchString(val, currentView)
                }
                showReset
            />
        </Toolbar>
    );
};

export default observer(ContentSearchBar);
