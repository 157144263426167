import React, { useEffect, useState, createContext } from 'react';
import WebFont from 'webfontloader';
import { BrowserRouter } from 'react-router-dom';
import Header from './components/Header/Header';
import AppRouter from './router/AppRouter';
import Auth from './store/auth';
import Interface from './store/interface';
import { observer } from 'mobx-react-lite';
import AppWrapper from './components/UI/AppWrapper/AppWrapper';
import Footer from './components/Footer';
import { useFetching } from './hooks/useFetching';
import './style/App.css';

export const auth = new Auth();
export const AuthContext = createContext({
    auth,
});

export const appInterface = new Interface();
export const InterfaceContext = createContext({
    appInterface,
});

const App = () => {
    const [isAuthChecked, setIsAuthChecked] = useState(false);
    const [areFontsLoaded, setAreFontsLoaded] = useState(false);

    const [checkAuth, isLoading, error] = useFetching(async () => {
        await auth.checkAuth();
        setIsAuthChecked(true);
    });

    const createCustomEvent = () => {
        document.addEventListener('click', (e) => {
            document.dispatchEvent(
                new CustomEvent('closeMenus', {
                    detail: e.target,
                })
            );
        });
    };

    useEffect(() => {
        checkAuth();
        createCustomEvent();
    }, []);

    useEffect(() => {
        WebFont.load({
            google: {
                families: [
                    'Inter:300,400,500,600,700,900',
                    'Montserrat:400,600',
                    'Noto Sans:300,400,500,600,700',
                    'Roboto:300',
                ],
            },
            active: () => {
                setAreFontsLoaded(true);
            },
        });
    }, []);

    useEffect(() => {
        setIsAuthChecked(true);
    }, [error]);

    if (isLoading || !isAuthChecked || !areFontsLoaded) {
        return <></>;
    }

    return (
        <AuthContext.Provider value={{ auth }}>
            <InterfaceContext.Provider value={{ appInterface }}>
                <BrowserRouter>
                    <AppWrapper>
                        <Header />
                        <AppRouter />
                        <Footer />
                    </AppWrapper>
                </BrowserRouter>
            </InterfaceContext.Provider>
        </AuthContext.Provider>
    );
};

export default observer(App);
