import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useFetching } from '../../../../../../hooks/useFetching';
import exercisesStore from '../../../../store/exercisesStore';

import ProgressService from '../../../../../../api/ProgressService';
import {
    Input,
    ToggleButton,
    Toolbar,
} from '../../../../../../teacherComponents';
import { Chapters, Views } from '../../../../../TeacherLessons/data/constants';
import {
    AddChapterModal,
    ChapterPlug,
    ChapterPreviewContainer,
    ChaptersScrollableList,
} from '../../../../components';
import CategoryWords from '../CategoryWords/CategoryWords';
import { FilterModes } from '../../../../data/constants';
import cl from './SelectDictionaryModal.module.css';
import { LanguageLevels } from '../../../../../../data/common';

const SelectDictionaryModal = ({
    visible,
    setVisible,
    onClick,
    withCategorySelector,
    alreadyAdded = [],
    isOnboarding,
    isOwn,
}) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;
    // const isLesson = exercisesStore.view === Views.Lessons;
    const isLesson = false;

    const [difficulty, setDifficulty] = useState('');
    const [currentWord, setCurrentWord] = useState();
    const [currentCategory, setCurrentCategory] = useState();
    const [categories, setCategories] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [mode, setMode] = useState(
        isLesson ? FilterModes.Teacher : FilterModes.All
    );

    const [getCategories, isLoading] = useFetching(async () => {
        const { data } =
            mode === FilterModes.All
                ? await ProgressService.getWordsCategories({
                      lang,
                      searchString,
                  })
                : await ProgressService.getUserCategories({
                      unassigned: false,
                  });

        await handleDictionaryResponse(data);
    });

    const handleDictionaryResponse = async (data) => {
        const currentLangCategories = data.items.filter((c) => c.lang === lang);

        const categoriesWithWords = await Promise.all(
            currentLangCategories.map(async (category) => {
                const { data: categoryWordsData } =
                    await ProgressService.getCategoryWords({
                        categoryId: category.id,
                        lang,
                    });

                return {
                    ...category,
                    words: categoryWordsData.items,
                };
            })
        );

        const withoutExisting = categoriesWithWords.filter(
            (e) => !alreadyAdded.some((a) => a.id === e.id)
        );

        setCategories(withoutExisting);
    };

    const handleCategorySelect = () => {
        if (!currentCategory) return;
        onClick(currentCategory);
        setVisible(false);
    };

    const handleClose = (flag) => {
        if (isOnboarding) return;
        setVisible(flag);
    };

    useEffect(() => {
        if (!visible) {
            setCategories([]);
            setCurrentCategory();
            return;
        }
        getCategories();
        setCurrentWord();
    }, [visible, lang, searchString, mode]);

    useEffect(() => {
        if (!categories.length) {
            setFiltered([]);
            return;
        }

        if (!difficulty) {
            setFiltered(categories);
            return;
        }
        setFiltered(
            categories.filter(
                (c) => c.level === LanguageLevels.indexOf(difficulty)
            )
        );
    }, [categories, difficulty]);

    useEffect(() => {
        setCurrentCategory(filtered[0]);
    }, [filtered]);

    useEffect(() => {
        setMode(isOwn ? FilterModes.Teacher : FilterModes.All);
    }, [isOwn]);

    return (
        <AddChapterModal
            visible={visible}
            setVisible={handleClose}
            type={Chapters.Dictionary}
            isOwn={isOwn}
        >
            <Toolbar>
                <ToggleButton
                    selectedOption={difficulty}
                    setOption={setDifficulty}
                    options={LanguageLevels}
                    nullable
                />
                <Input
                    variant={'outlinedSmall'}
                    placeholder={t('library.search')}
                    value={searchString}
                    onChange={setSearchString}
                />
                {withCategorySelector && (
                    <ToggleButton
                        selectedOption={mode}
                        setOption={setMode}
                        options={Object.values(FilterModes)}
                        withTranslations={'exercises'}
                    />
                )}
            </Toolbar>
            <div className={cl.tracksContainer}>
                <ChaptersScrollableList
                    type={Chapters.Dictionary}
                    isLoading={isLoading}
                    chapters={filtered}
                    current={currentCategory}
                    setCurrent={setCurrentCategory}
                    onSelect={handleCategorySelect}
                    onScroll={() => {}}
                />
                {currentCategory ? (
                    <ChapterPreviewContainer>
                        <CategoryWords
                            category={currentCategory}
                            lang={lang}
                            currentWord={currentWord}
                            setCurrentWord={setCurrentWord}
                        />
                    </ChapterPreviewContainer>
                ) : (
                    <ChapterPlug />
                )}
            </div>
        </AddChapterModal>
    );
};

export default observer(SelectDictionaryModal);
