import React, { useState } from 'react';
import Modal from '../../../../../../components/UI/Modal/Modal';
import { observer } from 'mobx-react-lite';
import onboardingStore from '../../../../../../store/onboarding';
import { ChapterModals, Chapters } from '../../../../data/constants';
import { Button, CloseButton } from '../../../../../../teacherComponents';
import {
    iconMap,
    titleMap,
} from '../../../../../TeacherContent/views/ExercisesView/data/constants';
import { useTranslation } from 'react-i18next';
import { SelectVideoModal } from '../../../../../TeacherContent/components';
import FileUploader from '../../../../../../components/UI/FileUploader/FileUploader';
import { AddVideoModal } from '../../../../../TeacherContent/views/TrackView/components';
import exercisesStore from '../../../../../TeacherContent/store/exercisesStore';
import { useFetching } from '../../../../../../hooks/useFetching';
import ProgressService from '../../../../../../api/ProgressService';
import TrackService from '../../../../../../api/TrackService';
import {
    GenerateExerciseModal,
    SelectExerciseModal,
} from '../../../../../TeacherContent/views/ExercisesView/components';
import ExerciseService from '../../../../../../api/ExerciseService';
import {
    GenerateSituationModal,
    SelectSituationModal,
} from '../../../../../TeacherContent/views/SituationsView/components';
import { SelectDictionaryModal } from '../../../../../TeacherContent/views/DictionaryView/components';
import { ReactComponent as IconAdd } from '../../../../../../assets/svg/lessons-add.svg';
import { ReactComponent as IconCloud } from '../../../../../../assets/svg/lessons-cloud.svg';
import { ReactComponent as IconStars } from '../../../../../../assets/svg/lessons-stars.svg';
import {
    ArrowPositions,
    BubblePositions,
    OnboardingKeys,
    OnboardingWrapper,
} from '../../../../../../teacherComponents/Onboarding';
import cl from './ContentModal.module.css';

const ContentModal = ({
    type,
    alreadyAdded,
    onAdd,
    onSituationAdd,
    onClose,
    onFileSelect,
}) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;

    const { dictionaries, exercises, situations, tracks } = alreadyAdded;

    const [isOwn, setIsOwn] = useState(false);

    const { chaptersModalType, difficulty } = exercisesStore;

    const handleClose = () => {
        exercisesStore.setChaptersModalType();
        onClose();

        const oldDifficulty = difficulty;

        exercisesStore.setDifficulty(oldDifficulty ? '' : 0);
        setTimeout(() => {
            exercisesStore.setDifficulty(oldDifficulty);
        }, 100);
    };

    const handleGenerateClick = () => {
        onClose();
        exercisesStore.setChaptersModalType(ChapterModals[type]);
    };

    const handleSelectClick = () => {
        onClose();
        exercisesStore.setChaptersModalType(type);
    };

    const handleOwnClick = () => {
        onClose();
        setIsOwn(true);
        setTimeout(() => {
            exercisesStore.setChaptersModalType(type);
        });
    };

    const handleModalClose = () => {
        if (onboardingStore.isOnboarding(OnboardingKeys.AwaitAddChapter))
            return;

        exercisesStore.setChaptersModalType();
        setIsOwn(false);
    };

    const renderCreateIcon = () => {
        switch (type) {
            case Chapters.Track:
                return <IconCloud />;
            case Chapters.Dictionary:
                return <IconStars />;
            case Chapters.Situation:
            case Chapters.Exercise:
                return <IconStars />;
            default:
                return '';
        }
    };

    const [addFavoriteTrack] = useFetching(async ({ id }) => {
        const { data } = await ProgressService.addTrack({
            trackId: id,
            language: lang,
        });
        if (data?.trackId) {
            const { data: trackData } = await TrackService.getTrack(
                data.trackId
            );
            onAdd({
                chapterId: id,
                title: trackData.title,
                chapterType: Chapters.Track,
            });
        }

        handleClose();
    });

    const [addNewFavoriteTrack] = useFetching(async (trackId) => {
        if (!isOwn) {
            await ProgressService.addTrack({
                trackId,
                language: lang,
            });
        }
        const { data: trackData } = await TrackService.getTrack(trackId);
        onAdd({
            chapterId: trackId,
            title: trackData.title,
            chapterType: Chapters.Track,
        });

        handleClose();
    });

    const [addExercise, addLoading] = useFetching(async ({ id }) => {
        if (!isOwn) {
            await ExerciseService.cloneExercise({ id });
        }
        const { data: exerciseResponse } = await ExerciseService.getExercise({
            id,
        });
        onAdd({
            chapterId: id,
            title: exerciseResponse.title,
            chapterType: Chapters.Exercise,
        });

        handleClose();
    });

    const handleExerciseGenerate = ({ id, title }) => {
        onAdd({
            chapterId: id,
            title,
            chapterType: Chapters.Exercise,
        });

        handleClose();
    };

    const handleSituationGenerate = () => {
        onSituationAdd();

        handleClose();
    };

    const [addFavoriteSituation] = useFetching(async ({ id, title }) => {
        if (!isOwn) {
            await ProgressService.addSituation({
                situationId: id,
                language: lang,
            });
        }

        onAdd({
            chapterId: id,
            title,
            chapterType: Chapters.Situation,
        });

        handleClose();
    });

    const [addCategory, addCategoryLoading] = useFetching(async (category) => {
        const { id, title } = category;
        if (!isOwn) {
            await ProgressService.cloneWordsCategory({
                id,
                lang,
            });
        }
        onAdd({
            chapterId: id,
            title,
            chapterType: Chapters.Dictionary,
        });

        handleClose();
    });

    if (type === Chapters.File)
        return (
            <FileUploader
                type={'pdf'}
                inputKey={'pdfUploader'}
                onSave={onFileSelect}
                visible={type === Chapters.File}
                setVisible={onClose}
                isLessons
            />
        );

    return (
        <>
            <Modal
                className={cl.contentModal}
                visible={type}
                setVisible={onClose}
            >
                <div className={cl.modalTitle}>
                    {iconMap[type]}
                    {t(`exercises.add_${type}`)}
                </div>
                <p className={cl.subtitle}>
                    {t('exercises.add_chapter_info_title')}
                </p>
                <p className={cl.subtitle}>
                    {t('exercises.add_chapter_info_subtitle')}
                </p>
                <OnboardingWrapper
                    onboardingKey={onboardingStore.isOnboarding(
                        OnboardingKeys.AddChapterInfo
                    )}
                    title={t('teacher_onboarding.select_chapter_info_title')}
                    subtitle={t(
                        'teacher_onboarding.select_chapter_info_subtitle'
                    )}
                    delay={3000}
                    bubblePosition={{
                        x: BubblePositions.x.Center,
                        y: BubblePositions.y.Top,
                    }}
                    showButton
                    bubbleAutoWidth
                >
                    <div className={cl.buttonsContainer}>
                        <Button
                            variant={type}
                            text={t(`exercises.add_new_${type}`)}
                            icon={renderCreateIcon()}
                            onClick={handleGenerateClick}
                        />

                        <Button
                            variant={type}
                            icon={<IconAdd />}
                            text={t(titleMap[type])}
                            onClick={handleOwnClick}
                        />

                        <OnboardingWrapper
                            onboardingKey={onboardingStore.isOnboarding(
                                OnboardingKeys.OpenPonyLibrary
                            )}
                            title={t('teacher_onboarding.pony_library')}
                            subtitle={t('teacher_onboarding.press_to_continue')}
                            delay={1200}
                            bubblePosition={{
                                x: BubblePositions.x.Center,
                                y: BubblePositions.y.Top,
                            }}
                            arrowPosition={ArrowPositions.Top}
                        >
                            <div style={{ width: '33%' }}>
                                <Button
                                    variant={type}
                                    icon={<IconAdd />}
                                    text={t(
                                        `teacher_onboarding.select_${type}`
                                    )}
                                    onClick={handleSelectClick}
                                    style={{ width: '100%' }}
                                />
                            </div>
                        </OnboardingWrapper>
                    </div>
                </OnboardingWrapper>
                <CloseButton onClick={onClose} />
            </Modal>
            <AddVideoModal
                lang={lang}
                visible={chaptersModalType === ChapterModals[Chapters.Track]}
                setVisible={handleModalClose}
                onAdd={addNewFavoriteTrack}
            />
            <SelectVideoModal
                visible={chaptersModalType === Chapters.Track}
                setVisible={handleModalClose}
                onClick={addFavoriteTrack}
                isOwn={isOwn}
                alreadyAdded={tracks}
            />
            <SelectExerciseModal
                visible={chaptersModalType === Chapters.Exercise}
                setVisible={handleModalClose}
                onClick={addExercise}
                isLoading={addLoading}
                isOwn={isOwn}
                alreadyAdded={exercises}
            />
            <GenerateExerciseModal
                visible={chaptersModalType === ChapterModals[Chapters.Exercise]}
                setVisible={handleModalClose}
                onAdd={handleExerciseGenerate}
            />
            <SelectSituationModal
                visible={chaptersModalType === Chapters.Situation}
                setVisible={handleModalClose}
                onClick={addFavoriteSituation}
                isOwn={isOwn}
                alreadyAdded={situations}
            />
            <SelectDictionaryModal
                visible={chaptersModalType === Chapters.Dictionary}
                setVisible={handleModalClose}
                onClick={addCategory}
                alreadyAdded={dictionaries}
                isOnboarding={false}
                isOwn={isOwn}
            />
            <GenerateSituationModal
                visible={
                    chaptersModalType === ChapterModals[Chapters.Situation]
                }
                setVisible={handleModalClose}
                onGenerate={handleSituationGenerate}
            />
            <Modal
                visible={
                    chaptersModalType === ChapterModals[Chapters.Dictionary]
                }
                setVisible={handleModalClose}
                className={cl.intro}
                style={{ zIndex: 15 }}
            >
                <p className={cl.title}>
                    {t(`exercises.words_pack_lesson_add_title`)}
                </p>
                <div className={cl.subtitle}>
                    {t(`exercises.words_pack_lesson_add_subtitle`)}
                </div>

                <Button
                    variant={'purple'}
                    text={t('teacher_onboarding.close_button')}
                    onClick={handleModalClose}
                />
            </Modal>
        </>
    );
};

export default observer(ContentModal);
