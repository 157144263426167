import React, { useEffect, useState } from 'react';
import i18n from '../../../../utils/i18next';
import { observer } from 'mobx-react-lite';
import player from '../../../../store/player';
import TranslationService from '../../../../api/TranslationService';
import cl from './Translation.module.css';

const Translation = ({ text }) => {
    const [translatedText, setTranslatedText] = useState(null);

    const { language } = i18n;

    useEffect(() => {
        if (text) {
            translateText(text, player.sourceLang);
        } else {
            setTranslatedText(null);
        }
    }, [text]);

    const translateText = async (text, sourceLang) => {
        if (language.toLowerCase() === sourceLang.toLowerCase()) return text;
        const { data } = await TranslationService.translateSentence({
            text,
            targetLang: language,
            sourceLang,
        });
        setTranslatedText(data.targetText);
    };

    return translatedText ? (
        <div className={cl.translation}>{translatedText}</div>
    ) : (
        <></>
    );
};

export default observer(Translation);
