import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import AddButton from '../../../../../../../../components/UI/AddButton/AddButton';
import InputLabel from '../../../../../../../../components/UI/InputLabel/InputLabel';
import { QuestionsLabel } from '../../../Preview/components';
import SentenceCard from './SentenceCard/SentenceCard';
import cl from './SentenceOrderEditor.module.css';

const SentenceOrderEditor = ({ exerciseData, onChange }) => {
    const { t } = useTranslation();
    const sentences = exerciseData.sentences || [];

    const [isAddDisabled, setIsAddDisabled] = useState(false);

    const handleSentenceAdd = () => {
        onChange({ sentences: [...sentences, { id: uuid(), text: '' }] });
    };

    const handleSentenceUpdate = (index, sentence) => {
        const newSentences = [...sentences];
        newSentences[index].text = sentence;
        onChange({ sentences: newSentences });
    };

    const handleSentenceDelete = (index) => {
        const newSentences = [...sentences];
        if (newSentences.length === 1) {
            newSentences[0] = { ...newSentences[0], text: '' };
            onChange({ sentences: newSentences });
            return;
        }
        newSentences.splice(index, 1);
        onChange({ sentences: newSentences });
    };

    const handleDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination || source.index === destination.index) return;

        const newSentences = [...sentences];
        const [removedSentence] = newSentences.splice(source.index, 1);
        newSentences.splice(destination.index, 0, removedSentence);
        onChange({ sentences: newSentences });
    };

    useEffect(() => {
        if (sentences[sentences.length - 1]) {
            setIsAddDisabled(!sentences[sentences.length - 1].text?.length);
        }
    }, [sentences]);

    return (
        <div className={cl.sentenceOrderEditor}>
            <QuestionsLabel label={'edit_sentences_order'} />
            <div className={cl.sentenceOrderInner}>
                {sentences.length ? (
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable
                            droppableId="sentence-list"
                            style={{ position: 'relative' }}
                        >
                            {(provided) => (
                                <div
                                    className={cl.sentenceOrderContainer}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {sentences.map((s, index) => (
                                        <SentenceCard
                                            key={s.id}
                                            sentence={s}
                                            index={index}
                                            onChange={handleSentenceUpdate}
                                            onDelete={handleSentenceDelete}
                                        />
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                ) : (
                    <InputLabel text={t('exercises.no_sentences')} />
                )}
                <AddButton
                    text={t(
                        sentences.length
                            ? 'exercises.new_sentence'
                            : 'exercises.first_sentence'
                    )}
                    onClick={handleSentenceAdd}
                    isDisabled={isAddDisabled}
                />
            </div>
        </div>
    );
};

export default SentenceOrderEditor;
