import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import dictionaryStore from '../../store/dictionaryStore';
import { InterfaceContext } from '../../../../App';
import { SortTypes, WordStatuses } from '../../data/constants';
import {
    Checkbox,
    LabeledInput,
    LabeledSelect,
    PrimaryButton,
    PrimaryInput,
} from '../../../../UI';
import cl from './SearchBar.module.css';

const SearchBar = () => {
    const { t } = useTranslation();

    const {
        language,
        status,
        searchString,
        checked,
        markOnOpen,
        multiselect,
        disableAutoScroll,
        sort,
        partOfSpeech,
        wordsCount,
    } = dictionaryStore;

    const { appInterface } = useContext(InterfaceContext);
    const { languages } = appInterface;

    const CheckedOptions = [
        { value: true, name: t('buttons.yes') },
        { value: false, name: t('buttons.no') },
        { value: '', name: t('podcasts.all') },
    ];

    const WordStatusOptions = [
        { value: WordStatuses.ok, name: t('dictionary_administration.valid') },
        {
            value: WordStatuses.invalid,
            name: t('dictionary_administration.invalid'),
        },
        {
            value: WordStatuses.translationError,
            name: t('dictionary_administration.translation_error'),
        },
        {
            value: WordStatuses.generationError,
            name: t('dictionary_administration.generation_error'),
        },
    ];

    const SortOptions = [
        {
            value: SortTypes.wordAsc,
            name: t('dictionary_administration.word_asc'),
        },
        {
            value: SortTypes.wordDesc,
            name: t('dictionary_administration.word_desc'),
        },
        {
            value: SortTypes.createdDesc,
            name: t('dictionary_administration.created_desc'),
        },
        {
            value: SortTypes.updatedDesc,
            name: t('dictionary_administration.updated_desc'),
        },
    ];

    const handleMultiSelectButton = () => {
        dictionaryStore.setMultiselect(true);
    };

    const handleSelectedDelete = () => {
        dictionaryStore.setMultiselect(false);
    };

    const handleSelectedRegenerate = () => {
        dictionaryStore.setMultiselect(false);
    };

    return (
        <div className={cl.searchBarCont}>
            <div className={cl.searchBar}>
                <PrimaryInput
                    style={{
                        flex: '25%',
                        width: 230,
                        fontWeight: 400,
                    }}
                    color={'light'}
                    placeholder={t('library.search')}
                    value={searchString}
                    onChange={dictionaryStore.setSearchString}
                />
                <LabeledInput
                    color={'light'}
                    placeholder={t('noun, verb, etc')}
                    label={t('dictionary_administration.part_of_speech')}
                    value={partOfSpeech}
                    onChange={dictionaryStore.setPartOfSpeech}
                />
                <LabeledSelect
                    label={t('dictionary_administration.source_lang')}
                    value={language}
                    options={languages}
                    onChange={dictionaryStore.setLanguage}
                />
                <LabeledSelect
                    label={t('library.status')}
                    value={status}
                    options={WordStatusOptions}
                    onChange={dictionaryStore.setStatus}
                />
                <LabeledSelect
                    label={t('dictionary_administration.checked')}
                    value={checked}
                    options={CheckedOptions}
                    onChange={dictionaryStore.setChecked}
                />
                <LabeledSelect
                    label={t('dictionary_administration.sort')}
                    value={sort}
                    options={SortOptions}
                    onChange={dictionaryStore.setSort}
                />
            </div>
            <div className={cl.subBar}>
                <p>{`${t('dictionary_administration.total')}${wordsCount}`}</p>
                {multiselect ? (
                    <>
                        <PrimaryButton
                            text={t('buttons.delete')}
                            onClick={handleSelectedDelete}
                            variant={'red'}
                            style={{ padding: '2px 12px', marginLeft: 5 }}
                        />
                        <PrimaryButton
                            text={t('dictionary_administration.regenerate')}
                            onClick={handleSelectedRegenerate}
                            variant={'dark'}
                            style={{ padding: '2px 12px', marginLeft: 5 }}
                        />
                    </>
                ) : (
                    <PrimaryButton
                        text={t('dictionary_administration.select_some')}
                        onClick={handleMultiSelectButton}
                        style={{ padding: '2px 12px', marginLeft: 5 }}
                    />
                )}

                <Checkbox
                    label={t('dictionary_administration.mark_when_open')}
                    value={markOnOpen}
                    onChange={dictionaryStore.setMarkOnOpen}
                />
                <Checkbox
                    label={t('dictionary_administration.disable_scroll')}
                    value={disableAutoScroll}
                    onChange={dictionaryStore.setDisableAutoScroll}
                />
            </div>
        </div>
    );
};

export default observer(SearchBar);
