import React, { useEffect, useState } from 'react';
import cl from './SummaryPreview.module.css';
import QuestionsLabel from '../QuestionsLabel/QuestionsLabel';

const SummaryPreview = ({
    exerciseObj,
    results,
    showAnswers,
    setShowAnswers,
}) => {
    const { data } = exerciseObj;

    const [active, setActive] = useState();

    const options = data?.options ?? [];

    const getOptionClassName = (o) => {
        const classNames = [cl.answer];
        if (showAnswers) {
            classNames.push(o.correct ? cl.correct : '');
        }
        classNames.push(
            active === o.id ? (o.correct ? cl.correct : cl.wrong) : ''
        );
        return classNames.join(' ');
    };

    useEffect(() => {
        if (results && results.options && results.selectedIndex) {
            const selectedOption = results.options[results.selectedIndex];
            setActive(selectedOption ? selectedOption.id : '');
        }
    }, [results]);

    useEffect(() => {
        if (showAnswers) setActive();
    }, [showAnswers]);

    return (
        <>
            <QuestionsLabel label={results ? 'student_answers' : 'options'} />
            <div className={cl.previewCont}>
                {options.map((o) => (
                    <div
                        className={getOptionClassName(o)}
                        onClick={() => {
                            setActive(active === o.id ? null : o.id);
                            setShowAnswers(false);
                        }}
                        key={o.id}
                    >
                        {o.text}
                    </div>
                ))}
            </div>
        </>
    );
};

export default SummaryPreview;
