import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import AdjustableTextarea from '../../../../../../../../components/UI/AdjustableTextarea/AdjustableTextarea';
import QuestionsLabel from '../../../Preview/components/QuestionsLabel/QuestionsLabel';
import AddButton from '../../../../../../../../components/UI/AddButton/AddButton';
import cl from './SummaryEditor.module.css';

const SummaryEditor = ({ exerciseData, onChange, disableSave }) => {
    const { t } = useTranslation();
    const [options, setOptions] = useState([]);

    const handleOptionChange = (text, id) => {
        const newOptions = [...options].map((o) =>
            o.id === id ? { ...o, text } : o
        );
        onChange({ ...exerciseData, options: newOptions });
    };

    const handleAddOption = () => {
        onChange({
            ...exerciseData,
            options: [...options, { id: uuid(), text: '', correct: false }],
        });
    };

    const handleOptionDelete = (id) => {
        onChange({
            ...exerciseData,
            options: options.filter((o) => o.id !== id),
        });
    };

    useEffect(() => {
        setOptions(exerciseData?.options ?? []);
    }, [exerciseData]);

    useEffect(() => {
        disableSave(options.some((o) => !o.text.length));
    }, [options]);

    return (
        <div className={cl.editorCont}>
            <QuestionsLabel label={'edit_options'} />
            {options.map((o) => (
                <AdjustableTextarea
                    className={`${cl.option} ${o.correct ? cl.correct : ''}`}
                    value={o.text}
                    onChange={(text) => handleOptionChange(text, o.id)}
                    key={o.id}
                    mode={o.correct ? '' : 'sentence'}
                    onDelete={() => handleOptionDelete(o.id)}
                    isFocus={!o.text.length}
                />
            ))}
            <AddButton
                text={t('situations.add_other')}
                onClick={handleAddOption}
            />
        </div>
    );
};

export default observer(SummaryEditor);
