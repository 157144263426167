import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../../../hooks/useFetching';
import ProgressService from '../../../../../../api/ProgressService';
import Modal from '../../../../../../components/UI/Modal/Modal';
import {
    Button,
    CloseButton,
    Input,
    Label,
    ToggleButton,
} from '../../../../../../teacherComponents';
import { LanguageLevels } from '../../../../../../data/common';
import { Chapters } from '../../../../../TeacherLessons/data/constants';
import cl from './CreateWordsCategoryModal.module.css';

const CreateWordsCategoryModal = ({ visible, setVisible, lang, onAdd }) => {
    const { t } = useTranslation();

    const [title, setTitle] = useState('');
    const [difficulty, setDifficulty] = useState('');
    const [formErrors, setFormErrors] = useState({});

    const [createCategory, createLoading] = useFetching(async () => {
        const { data } = await ProgressService.createWordsCategory({
            title,
            lang,
            level: LanguageLevels.indexOf(difficulty),
        });
        if (data) {
            setVisible(false);
            setTitle('');
            onAdd(data);
        }
    });

    const resetErrors = () => {
        setFormErrors({});
    };

    const handleWordsGenerate = () => {
        resetErrors();

        setTimeout(() => {
            const errors = {
                title: !title,
                difficulty: !difficulty,
            };
            setFormErrors(errors);

            if (Object.values(errors).some((e) => e)) return;

            createCategory();
        });
    };

    useEffect(() => {
        setTitle('');
        setDifficulty('');
        setFormErrors({});
    }, [visible]);

    useEffect(() => {
        setFormErrors({});
    }, [title, difficulty]);

    return (
        <Modal
            className={cl.generateModal}
            visible={visible}
            setVisible={setVisible}
        >
            <p className={cl.title}>{t('exercises.create_words_category')}</p>
            <p className={cl.subtitle}>
                {t('exercises.create_words_category_desc')}
            </p>
            <div>
                <Label
                    text={t('exercises.words_category_title')}
                    isError={formErrors['title']}
                />
                <Input
                    variant={
                        formErrors['title'] ? 'erroredSmall' : 'outlinedSmall'
                    }
                    placeholder={t(
                        'exercises.words_category_title_placeholder'
                    )}
                    value={title}
                    onChange={setTitle}
                />
            </div>
            <div>
                <Label
                    text={t('situations.level')}
                    isError={formErrors['difficulty']}
                />
                <ToggleButton
                    style={{ width: 212 }}
                    label={t('users.level')}
                    options={LanguageLevels}
                    selectedOption={difficulty}
                    variant={formErrors['level'] ? 'error' : 'outlined'}
                    setOption={setDifficulty}
                />
            </div>
            <Button
                variant={Chapters.Dictionary}
                text={t('buttons.create')}
                onClick={handleWordsGenerate}
                isLoading={createLoading}
                style={{ margin: '0 auto', marginTop: 30 }}
            />
            <CloseButton onClick={() => setVisible(false)} />
        </Modal>
    );
};

export default CreateWordsCategoryModal;
