import { blankNotifyId, defaultTime } from '../data';

export const timeToDate = (str) => {
    const now = new Date();
    const [hours, minutes] = str.split(':').map(Number);
    const timezoneOffset = now.getTimezoneOffset();
    now.setHours(hours - timezoneOffset / 60, minutes, 0, 0);
    return now;
};

export const dateToTime = (date) => {
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
};

export const getTimeWithTimezone = (str) => {
    const now = new Date();
    const [hours, minutes] = str.split(':').map(Number);
    const timezoneOffset = now.getTimezoneOffset();
    return `${hours - timezoneOffset / 60}:${minutes.toString().padStart(2, '0')}`;
};

export const getNotifyBody = () => ({
    id: blankNotifyId,
    title: '',
    text: '',
    time: defaultTime,
    localizations: [],
});
