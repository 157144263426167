import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import situationsStore from '../../store/situationsStore';
import { useFetching } from '../../../../hooks/useFetching';
import SituationsService from '../../../../api/SituationsService';
import { PrimarySelect, ToggleButton } from '../../../../UI';
import {
    NavigationWrapper,
    Statements,
    SituationControlsBar,
    SituationTitle,
} from '../';
import { getAvailableOptions } from '../../helpers/getAvailableOptions';
import { GENDERS, MODALITIES } from '../../data/constants';

const Situation = () => {
    const { language, languageOptions, setLanguage } = situationsStore;

    const { id, title, info, processed, level } =
        situationsStore.currentSituation;
    const [gender, setGender] = useState(info[0]?.gender || GENDERS[0]);
    const [modality, setModality] = useState(
        info[0]?.modality || MODALITIES[0]
    );
    const [levels, setLevels] = useState(level);
    const [availableOptions, setAvailableOptions] = useState({});

    const situationOptions = {
        gender,
        modality,
    };

    const [getSituation, isSituationLoading, isEmpty, resetEmpty] = useFetching(
        async () => {
            const { data } = await SituationsService.getSituation({
                id,
                language,
                gender,
                modality,
                targetLanguage: language,
            });
            const { statements, info } = data;
            situationsStore.setCurrentSituation({
                ...situationsStore.currentSituation,
                statements,
                situationInfoId: info.id,
                processed: info.processed,
            });
            resetEmpty();
        }
    );

    const handleGenderChange = (gender) => {
        if (
            availableOptions[gender] &&
            Object.keys(availableOptions[gender])?.length > 0
        ) {
            handleModalityChange(Object.keys(availableOptions[gender])[0]);
        }
        setGender(gender);
    };

    const handleModalityChange = (modality) => {
        setModality(modality);
    };

    const onLevelChange = (newLevels) => {
        setLevels(newLevels.sort((a, b) => a.localeCompare(b)));
        situationsStore.setWasChanged(true);
    };

    const getAvailableGenders = () => Object.keys(availableOptions) || [];

    const getAvailableModalities = () => {
        if (availableOptions[gender]) {
            return Object.keys(availableOptions[gender]) || [];
        } else {
            return [];
        }
    };

    useEffect(() => {
        setTimeout(() => {
            getSituation();
        }, 100);
    }, [language, modality, gender]);

    useEffect(() => {
        setAvailableOptions(getAvailableOptions(info));
    }, [info]);

    useEffect(() => {
        if (!situationsStore.currentSituation) return;
    }, [situationsStore.currentSituation]);

    useEffect(() => {
        if (!info?.length) {
            setAvailableOptions({});
            setLevels([]);
        } else {
            setAvailableOptions(getAvailableOptions(info));
            setLevels(situationsStore.currentSituation.level);
        }
    }, [info]);

    return (
        <>
            <SituationTitle
                id={id}
                processed={processed}
                levels={levels}
                title={title}
                onChange={onLevelChange}
                isLoading={isSituationLoading}
            />
            <NavigationWrapper>
                <ToggleButton
                    options={GENDERS}
                    availableOptions={getAvailableGenders()}
                    selectedOption={gender}
                    setOption={handleGenderChange}
                    withTranslations={'situations'}
                />
                <ToggleButton
                    options={MODALITIES}
                    availableOptions={getAvailableModalities()}
                    selectedOption={modality}
                    setOption={handleModalityChange}
                    withTranslations={'situations'}
                />
                <PrimarySelect
                    value={language}
                    options={languageOptions}
                    onChange={setLanguage}
                    style={{ width: 140, minWidth: 140 }}
                />
                <SituationControlsBar
                    options={situationOptions}
                    availableOptions={availableOptions}
                    isEmpty={isEmpty}
                    isEmptyInfo={!info?.length}
                    isSituationLoading={isSituationLoading}
                />
            </NavigationWrapper>

            <Statements isEmpty={isEmpty} isLoading={isSituationLoading} />
        </>
    );
};

export default observer(Situation);
