import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import exercisesStore from '../../../../store/exercisesStore';
import exercisesPlayer from '../../../../store/exercisesPlayer';
import { useFetching } from '../../../../../../hooks/useFetching';
import ExerciseService from '../../../../../../api/ExerciseService';
import {
    Button,
    ToggleButton,
    Range,
    Select,
    Input,
    Label,
} from '../../../../../../teacherComponents';
import {
    MediaExerciseTypes,
    MediaTypes,
    QuestionsRange,
    SummarQuestionsRange,
    TextModes,
} from '../../../../data/constants';
import { LanguageLevels } from '../../../../../../data/common';
import { GenerateText, SelectMedia } from '..';
import { PresentationTypes } from '../../data/constants';
import { Chapters } from '../../../../../TeacherLessons/data/constants';
import cl from './MediaOptions.module.css';

const TICKS_IN_SECOND = 10000000;

const MediaOptions = ({ options, setOptions, onCreate }) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;

    const { currentTrack, generatedText } = exercisesStore;
    const { title, description, type, difficulty, mediaType, sentencesAmount } =
        options;

    const [formErrors, setFormErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');

    const isVideo = mediaType === MediaTypes.Video;

    const rangeParams =
        options?.type === MediaExerciseTypes.Summary
            ? SummarQuestionsRange
            : QuestionsRange;

    const presentationOptions = Object.values(PresentationTypes).map(
        (type) => ({
            name: t(`presentation_options.${type}_title`),
            value: t(
                `presentation_options.${type}_${isVideo ? 'video' : 'text'}_description`
            ),
        })
    );

    const getIsDisabled = () => {
        if (!type) return true;
        if (
            mediaType === MediaTypes.GeneratedText &&
            !exercisesStore.generatedText?.length
        )
            return true;
        if (
            type === MediaExerciseTypes.Presentation &&
            (!title || !description)
        )
            return false;
    };

    const setSentencesAmount = (e) => {
        setOptions({ ...options, sentencesAmount: e.target.value });
    };

    const setTitle = (title) => {
        setOptions({ ...options, title });
    };

    const setDescription = (description) => {
        setOptions({ ...options, description });
    };

    const setDifficulty = (difficulty) => {
        setOptions({ ...options, difficulty });
    };

    const getText = () => {
        return mediaType === MediaTypes.GeneratedText
            ? exercisesStore.generatedText
            : exercisesStore.trackText;
    };

    const isRange = () =>
        ![MediaExerciseTypes.Presentation].includes(options.type);

    const [getChatResponse, isChatLoading, chatError, resetError] = useFetching(
        async () => {
            const withMedia = ![MediaTypes.GeneratedText].includes(mediaType);
            const withInterval = ![
                MediaTypes.Text,
                MediaTypes.GeneratedText,
            ].includes(mediaType);

            const { data } = await ExerciseService.createExercise({
                ...options,
                trackId: withMedia ? currentTrack?.id : null,
                trackInterval: withInterval
                    ? exercisesPlayer.currentRange.map(
                          (r) => r / TICKS_IN_SECOND
                      )
                    : null,
                text: getText(),
                difficulty: LanguageLevels.indexOf(difficulty),
                title,
                description,
                lang,
            });
            if (data) {
                onCreate(data);
            }
        }
    );

    const isReadyToGenerateExercise = () => {
        if (mediaType === MediaTypes.GeneratedText && generatedText)
            return true;
        if (mediaType !== MediaTypes.GeneratedText && currentTrack) return true;
        return false;
    };

    const handleExerciseGenerate = () => {
        setFormErrors({});
        setTimeout(() => {
            const errors = {
                title:
                    !options.title && type === MediaExerciseTypes.Presentation,
                description:
                    !options.description &&
                    type === MediaExerciseTypes.Presentation,
                level: !options.difficulty,
            };
            setFormErrors(errors);
            if (Object.values(errors).some((e) => e)) return;

            getChatResponse();
        });
    };

    useEffect(() => {
        if (isRange()) {
            setOptions({ ...options, sentencesAmount: rangeParams.Default });
        }
    }, [options.mediaType, rangeParams]);

    useEffect(() => {
        setErrorMessage(chatError?.message);
    }, [chatError]);

    useEffect(() => {
        const range = exercisesPlayer.currentRange;
        if (!range?.length) {
            exercisesStore.setTrackText(
                exercisesPlayer.trackSentences?.map((t) => t.text).join(' ')
            );
            return;
        }

        const segment = exercisesPlayer.trackSentences?.filter(
            (t) => t.offset >= range[0] && t.offset <= range[1]
        );
        exercisesStore.setTrackText(segment?.map((t) => t.text).join(' '));
    }, [exercisesPlayer.currentRange]);

    useEffect(
        () => () => {
            exercisesStore.resetTrackParams();
            exercisesPlayer.resetMediaParams();
        },
        []
    );

    useEffect(() => {
        exercisesPlayer.setMediaRange(exercisesPlayer.currentRange);
    }, [mediaType]);

    useEffect(() => {
        setFormErrors({});
    }, [options]);

    return (
        <div className={cl.exerciseOptions}>
            <SelectMedia mediaType={mediaType} />
            {isReadyToGenerateExercise() && (
                <>
                    <div className={cl.paramsContainer}>
                        <div
                            className={cl.toggleContainer}
                            style={{ maxWidth: 212 }}
                        >
                            <Label
                                text={t('exercises.exercise_level')}
                                isError={formErrors['level']}
                            />
                            <ToggleButton
                                label={t('users.level')}
                                options={LanguageLevels}
                                selectedOption={options.difficulty}
                                variant={
                                    formErrors['level'] ? 'error' : 'outlined'
                                }
                                setOption={setDifficulty}
                            />
                        </div>
                    </div>
                    {type === MediaExerciseTypes.Presentation && (
                        <div className={cl.paramsContainer}>
                            <div className={cl.toggleContainer}>
                                <Label
                                    text={t('exercises.exercise_title')}
                                    isError={formErrors['title']}
                                />
                                <Input
                                    variant={
                                        formErrors['title']
                                            ? 'erroredSmall'
                                            : 'outlinedSmall'
                                    }
                                    placeholder={t('exercises.enter_title')}
                                    value={title}
                                    onChange={setTitle}
                                />
                            </div>
                            <div className={cl.toggleContainer}>
                                <Label
                                    text={t('exercises.presentation_task')}
                                    isError={formErrors['description']}
                                />
                                <Select
                                    label={t('exercises.select_value')}
                                    value={description}
                                    onChange={setDescription}
                                    variant={'grey'}
                                    options={presentationOptions}
                                    height={200}
                                    style={{ minWidth: 250 }}
                                />
                            </div>
                        </div>
                    )}
                    {isRange() && (
                        <div>
                            <Label
                                text={t(
                                    type === MediaExerciseTypes.SentencesOrder
                                        ? 'exercises.sentence_count'
                                        : type === MediaExerciseTypes.Summary
                                          ? 'exercises.summary_count'
                                          : 'exercises.question_count'
                                )}
                            />
                            <Range
                                color={'#2f83ea'}
                                style={{ marginTop: 15 }}
                                value={sentencesAmount ?? rangeParams.Default}
                                min={rangeParams.Min}
                                step={rangeParams.Step}
                                max={rangeParams.Max}
                                onChange={setSentencesAmount}
                            />
                        </div>
                    )}
                </>
            )}
            {description && (
                <Label
                    text={`${t('exercises.presentation_task')}: ${description}`}
                />
            )}
            <GenerateText mediaType={mediaType} allowEmptyGrammar />

            {isReadyToGenerateExercise() &&
                (errorMessage ? (
                    <div className={cl.optionsContainer}>
                        <p className={cl.errorMessage}>{`${t(
                            'dictionary_administration.generation_error'
                        )} (${errorMessage})`}</p>
                        <Button
                            text={t('buttons.reset')}
                            variant={'red'}
                            onClick={resetError}
                        />
                    </div>
                ) : (
                    <>
                        <div className={cl.optionsContainer}>
                            <Button
                                variant={Chapters.Exercise}
                                text={t('exercises.generate_exercise')}
                                isDisabled={getIsDisabled()}
                                onClick={handleExerciseGenerate}
                                isLoading={isChatLoading}
                            />
                            {exercisesStore.textMode === TextModes.Generated &&
                                mediaType === MediaTypes.Text && (
                                    <Button
                                        text={t('buttons.back')}
                                        variant={'grey'}
                                        onClick={() => {
                                            exercisesStore.setGeneratedText(
                                                null
                                            );
                                        }}
                                    />
                                )}
                        </div>
                    </>
                ))}
        </div>
    );
};

export default observer(MediaOptions);
