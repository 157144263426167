import { makeAutoObservable } from 'mobx';

class LessonsStore {
    themes = [];
    teacherThemes = [];

    searchString = '';
    difficulty = '';
    age = '';

    currentLesson = null;
    currentStudentLessonsLoading = false;
    currentStudentLessons = [];
    currentChapter = {};
    isAddLesson = false;

    collapseCards = localStorage.getItem('collapseCards')
        ? JSON.parse(localStorage.getItem('collapseCards'))
        : false;
    filterByStudent = false;

    isResetStudent = false;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true, deep: true });
    }

    setThemes(themes) {
        this.themes = themes;
    }

    setTeacherThemes(teacherThemes) {
        this.teacherThemes = teacherThemes;
    }

    addTheme(theme) {
        this.setThemes([...this.themes, theme]);
    }

    updateTheme(theme) {
        this.setThemes(this.themes.map((t) => (t.id === theme.id ? theme : t)));
    }

    addLesson(themeId, lesson) {
        this.setThemes(
            this.themes.map((t) =>
                t.id === themeId ? { ...t, lessons: [...t.lessons, lesson] } : t
            )
        );
    }

    setLessons(themeId, lessons) {
        this.setThemes(
            this.themes.map((t) => (t.id === themeId ? { ...t, lessons } : t))
        );
    }

    updateLesson(newLesson) {
        this.updateThemeLesson(newLesson);
        this.setCurrentLesson(newLesson);
    }

    updateThemeLesson(newLesson) {
        const newThemes = this.themes.map((t) =>
            t.lessons.some((l) => l.id === newLesson.id)
                ? {
                      ...t,
                      lessons: t.lessons.map((l) =>
                          l.id === newLesson.id ? newLesson : l
                      ),
                  }
                : t
        );
        this.setThemes(newThemes);
    }

    deleteThemeLesson(lessonId) {
        const newThemes = this.themes.map((t) => ({
            ...t,
            lessons: t.lessons.filter((l) => l.id !== lessonId),
        }));
        this.setThemes(newThemes);
        this.setCurrentLesson(null);
    }

    setCurrentLesson(currentLesson) {
        this.currentLesson = currentLesson;
    }

    setCurrentStudentLessons(currentStudentLessons) {
        this.currentStudentLessons = currentStudentLessons;
    }

    setCurrentStudentLessonsLoading(currentStudentLessonsLoading) {
        this.currentStudentLessonsLoading = currentStudentLessonsLoading;
    }

    setSearchString(searchString) {
        this.searchString = searchString;
    }

    setDifficulty(difficulty) {
        this.difficulty = difficulty;
    }

    setAge(age) {
        this.age = age;
    }

    setIsAddLesson(isAddLesson) {
        this.isAddLesson = isAddLesson;
    }

    deleteTheme(themeId) {
        this.setThemes(this.themes.filter((t) => t.id !== themeId));
    }

    toggleCollapseCards() {
        localStorage.setItem(
            'collapseCards',
            JSON.stringify(!this.collapseCards)
        );
        this.collapseCards = !this.collapseCards;
    }

    setFilterByStudent(filterByStudent) {
        this.filterByStudent = filterByStudent;
    }

    toggleFilterByStudent() {
        this.setFilterByStudent(!this.filterByStudent);
    }

    setCurrentChapter(currentChapter) {
        this.currentChapter = currentChapter;
    }

    setIsResetStudent(isResetStudent) {
        this.isResetStudent = isResetStudent;
    }

    resetSearch() {
        this.searchString = '';
        this.difficulty = '';
        this.age = '';
    }

    resetState() {
        this.themes = [];
        this.teacherThemes = [];

        this.searchString = '';
        this.difficulty = '';
        this.age = '';

        this.currentLesson = null;
        this.currentStudentLessonsLoading = false;
        this.currentStudentLessons = [];
        this.currentChapter = {};
        this.isAddLesson = false;

        this.collapseCards = localStorage.getItem('collapseCards')
            ? JSON.parse(localStorage.getItem('collapseCards'))
            : false;
        this.filterByStudent = false;

        this.sResetStudent = false;
    }
}

const lessonsStore = new LessonsStore();
export default lessonsStore;
