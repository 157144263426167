import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { AuthContext } from '../../../../App';
import exercisesStore from '../../../TeacherContent/store/exercisesStore';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../router';
import { Button } from '../../../../teacherComponents';
import { Typography } from '..';
import gradesRightSrc from '../../../../assets/webp/grades_1.webp';
import gradesLeftSrc from '../../../../assets/webp/grades_2.webp';
import { Roles } from '../../../../data/common';
import cl from './FirstSection.module.css';

const initialImageStyle = { opacity: 0, top: '60%' };
const animateImageStyle = { opacity: 1, top: '55%' };

const FirstSection = () => {
    const { t } = useTranslation();
    const { auth } = useContext(AuthContext);

    const isGuest = auth.role === Roles.Guest;
    const navigate = useNavigate();

    const { wasTutorialLoaded } = exercisesStore;

    const [leftImgLoaded, setLeftImgLoaded] = useState(false);
    const [rightImgLoaded, setRightImgLoaded] = useState(false);

    const handleStartButtonClick = () => {
        navigate(ROUTES.lessons);
    };

    const getInitial = () => {
        return wasTutorialLoaded ? animateImageStyle : initialImageStyle;
    };

    const getAnimate = (isLoaded) => {
        return wasTutorialLoaded && isLoaded ? animateImageStyle : {};
    };

    useEffect(() => {
        if (leftImgLoaded && rightImgLoaded) {
            exercisesStore.setWasTutorialLoaded(true);
        }
    }, [leftImgLoaded, rightImgLoaded]);

    return (
        <div className={cl.firstSection}>
            <div className={cl.sectionInner}>
                <motion.img
                    className={cl.gradesImg}
                    src={gradesLeftSrc}
                    alt="grades"
                    initial={getInitial()}
                    animate={getAnimate(leftImgLoaded)}
                    transition={{ duration: 1 }}
                    onLoad={() => setLeftImgLoaded(true)}
                />
                <Typography
                    variant={'h1'}
                    className={cl.mainTitle}
                    dangerouslySetInnerHTML={t('teacher_tutorial.title')}
                />
                {!isGuest && (
                    <Button
                        variant={'dark'}
                        text={t('teacher_tutorial.get_started')}
                        onClick={handleStartButtonClick}
                    />
                )}
                <motion.img
                    className={cl.gradesImg}
                    src={gradesRightSrc}
                    alt="grades"
                    initial={getInitial()}
                    animate={getAnimate(leftImgLoaded)}
                    transition={{ duration: 1 }}
                    onLoad={() => setRightImgLoaded(true)}
                />
            </div>
        </div>
    );
};

export default observer(FirstSection);
