import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cl from './ToggleButton.module.css';

const ToggleButton = ({
    title,
    options,
    nullable,
    selectedOption,
    availableOptions,
    setOption,
    style,
    variant,
    withTranslations,
    multiple,
    disabled,
    isGrid,
    icons,
}) => {
    const { t } = useTranslation();

    const [pressedIndex, setPressedIndex] = useState(null);

    const handleOptionClick = (option) => {
        if (
            availableOptions !== undefined &&
            !availableOptions?.includes(option) &&
            disabled
        )
            return;
        if (multiple) {
            setOption(
                selectedOption.includes(option)
                    ? selectedOption.filter((o) => o !== option)
                    : [...selectedOption, option]
            );
            return;
        }
        setOption(selectedOption === option && nullable ? null : option);
    };

    const getOptionClassName = (option, index) => {
        const classNames = [cl.option];
        if (!multiple && option === selectedOption) classNames.push(cl.active);
        if (multiple && selectedOption.includes(option))
            classNames.push(cl.active);
        if (variant) classNames.push(cl[variant]);
        if (index === pressedIndex) {
            classNames.push(cl.pressed);
        }
        if (
            availableOptions !== undefined &&
            !availableOptions?.includes(option)
        ) {
            if (disabled) classNames.push(cl.disabled);
            classNames.push(cl.unavailable);
        }
        return classNames.join(' ');
    };

    return (
        <div className={`${cl.toggleButtonCont} ${cl[variant]}`} style={style}>
            {title && <p className={cl.sectionTitle}>{`${t(title)}:`}</p>}
            <div
                className={`${cl.optionsContainer} ${isGrid ? cl.grid : ''}`}
                style={{
                    gridTemplateColumns: isGrid
                        ? `repeat(${options.length}, 1fr)`
                        : 'unset',
                }}
            >
                {options.map((option, index) => (
                    <div
                        className={getOptionClassName(option, index)}
                        key={`${option}_${index}`}
                        onClick={() => handleOptionClick(option)}
                        onMouseDown={() => setPressedIndex(index)}
                        onMouseUp={() => setPressedIndex(null)}
                    >
                        {icons?.length && icons[index]}
                        {withTranslations
                            ? t(`${withTranslations}.${option.toLowerCase()}`)
                            : option}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ToggleButton;
