import { LanguageLevels } from '../../../data/common';

export const ExercisesPaginationCount = 100;

export const Ages = ['7-', '7-9', '10-12', '12-14', '15-17', '18+'];

export const MediaTypes = {
    Video: 'video_type',
    Audio: 'audio_type',
    Text: 'text_type',
    GeneratedText: 'generated_type',
};

export const GrammarExerciseTypes = {
    Substitution: 'substitution',
    Multichoice: 'multichoice',
    Cloze: 'cloze',
    WordsOrder: 'words_order',
    StatementsTransformation: 'statements_transformation',
};

export const MediaExerciseTypes = {
    SentencesOrder: 'sentence_order',
    Quiz: 'quiz',
    AdvancedQuiz: 'advanced_quiz',
    Summary: 'summary',
    Presentation: 'presentation',
    FreeFormQuestions: 'free_form_questions',
};

export const TextGenres = {
    Text: 'text',
    Story: 'fictional story',
    Article: 'descriptive article',
    Newspaper: 'newspaper article',
    Argumentative: 'argumentative article',
    Blog: 'blog post',
    Review: 'review',
    Letter: 'formal letter',
    InformalLetter: 'informal letter',
};

export const TextModes = {
    Generated: 'generated',
    Custom: 'custom',
};

export const TextRange = {
    Min: 5,
    Step: 1,
    Max: 50,
    Default: '5',
};

export const SentencesRange = {
    Min: 1,
    Step: 1,
    Max: 10,
    Default: '5',
};

export const QuestionsRange = {
    Min: 1,
    Step: 1,
    Max: 10,
    Default: '5',
};

export const SummarQuestionsRange = {
    Min: 3,
    Step: 1,
    Max: 5,
    Default: '3',
};

export const DefaultExerciseOptions = {
    difficulty: '',
    type: '',
    topic: '',
    setting: '',
    sentencesAmount: SentencesRange.Default,
};

export const DefaultMediaExerciseOptions = {
    title: '',
    description: '',
    difficulty: '',
    type: '',
    topic: '',
    mediaType: MediaTypes.Video,
    sentencesAmount: SentencesRange.Default,
};

export const DefaultView = 'default';

export const MaximumMediaCharactersAmount = 3000;

export const AdvancedQuizStatuses = ['true', 'false', 'not_related'];

export const TrackPaginationLimit = 30;

export const FilterModes = {
    Teacher: 'teacher_tracks',
    All: 'all_tracks',
};

export const Exams = {
    en: ['IELTS', 'PTE', 'TOEFL'],
    ru: ['ТРКИ'],
    de: ['DSH', 'Goethe'],
};

export const getExamOptions = (lang) =>
    Exams[lang].map((o) => ({
        value: o,
        name: o,
    }));

export const AgeOptions = Object.values(Ages).map((o, i) => ({
    value: i,
    name: o,
}));

export const LevelOptions = Object.values(LanguageLevels).map((o, i) => ({
    value: i,
    name: o,
}));
