import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import situationsStore from '../../store/situationsStore';
import { useFetching } from '../../../../hooks/useFetching';
import SituationsService from '../../../../api/SituationsService';
import { PrimaryButton, PrimaryInput, RefreshButton } from '../../../../UI';
import {
    AddSituation,
    BackButton,
    EntitiesContainer,
    NavigationWrapper,
    SituationCard,
} from '..';
import { ReactComponent as IconClose } from '../../../../assets/img/icon-cross.svg';
import { ReactComponent as IconEdit } from '../../../../assets/img/icon-edit.svg';
import cl from './SituationCategory.module.css';

const SituationCategory = ({ isLoading, onRefresh }) => {
    const { t } = useTranslation();
    const { currentCategory, language } = situationsStore;
    const { id, title } = currentCategory;
    const intervalRef = useRef();

    const [situations, setSituations] = useState([]);
    const [queue, setQueue] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [newTitle, setNewTitle] = useState(title);

    const handleCategoryClose = () => {
        situationsStore.setCurrentCategory(null);
    };

    const [changeTitle, isChangeLoading] = useFetching(async () => {
        if (title === newTitle) {
            setIsEdit(false);
            return;
        }
        const { data } = await SituationsService.updateCategoryTitle({
            id,
            title: newTitle,
            language,
        });
        if (data && data.title) {
            setIsEdit(false);
            situationsStore.setCurrentCategoryTitle(data.title);
        }
    });

    const [getQueue] = useFetching(async () => {
        const { data } = await SituationsService.getCategoryQueue({
            id,
        });
        setQueue(data.filter((s) => s.language === situationsStore.language));
    });

    useEffect(() => {
        if (!currentCategory) return;
        getQueue();
        setSituations(currentCategory.situations);
    }, [currentCategory]);

    useEffect(
        () => () => {
            clearInterval(intervalRef.current);
        },
        []
    );

    useEffect(() => {
        setNewTitle(title);
    }, [isEdit]);

    return (
        <>
            <NavigationWrapper>
                <BackButton
                    onClick={handleCategoryClose}
                    text={'situations.categories_btn'}
                />
                {isEdit ? (
                    <div className={cl.titleEditor}>
                        <PrimaryButton
                            variant={'dark'}
                            icon={<IconClose className={cl.closeIcon} />}
                            onClick={() => setIsEdit(false)}
                            style={{ padding: '9.5px 11px' }}
                        />
                        <PrimaryInput
                            value={newTitle}
                            onChange={setNewTitle}
                            placeholder={'General Language'}
                        />
                        <PrimaryButton
                            variant={'dark'}
                            text={t('buttons.save')}
                            onClick={changeTitle}
                            isLoading={isChangeLoading}
                            isDisabled={!newTitle.length}
                        />
                    </div>
                ) : (
                    <div
                        className={cl.categoryTitle}
                        onClick={() => setIsEdit(true)}
                    >
                        {title} <span>{`(${situationsStore.language})`}</span>
                        <IconEdit className={cl.editIcon} />
                    </div>
                )}
                <div className={cl.controls}>
                    <AddSituation
                        categoryId={id}
                        language={situationsStore.language}
                    />
                    <RefreshButton isLoading={isLoading} onClick={onRefresh} />
                </div>
            </NavigationWrapper>
            <EntitiesContainer>
                {[...situations, ...queue].map((sit) => (
                    <SituationCard key={sit.id} situation={sit} />
                ))}
            </EntitiesContainer>
        </>
    );
};

export default observer(SituationCategory);
