import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext, InterfaceContext } from '../../App';
import onboardingStore from '../../store/onboarding';
import studentsStore from '../../pages/TeacherStudents/store/studentsStore';
import teachersStore from '../../pages/Teachers/store/teachersStore';
import { PrimaryButton, StyledNavLink } from '../../UI';
import { ROUTES } from '../../router';
import BackPageArrow from '../UI/BackPageArrow/BackPageArrow';
import { Button, NavigationSelect, Select } from '../../teacherComponents';
import { ReactComponent as TeacherLogo } from '../../assets/svg/lessons-logo.svg';
import { ReactComponent as TeacherLogoText } from '../../assets/svg/lessons-logo-text.svg';
import { ReactComponent as IconLogout } from '../../assets/img/icon-logout.svg';
import { ReactComponent as IconProfile } from '../../assets/img/icon-profile.svg';
import { ReactComponent as IconTeacherProfile } from '../../assets/svg/lessons-profile.svg';
import { Roles } from '../../data/common';
import {
    BubblePositions,
    OnboardingWrapper,
    OnboardingKeys,
    ArrowPositions,
} from '../../teacherComponents/Onboarding';
import AuthModal from '../AuthModal/AuthModal';
import { AuthModes } from '../../store/auth';
import cl from './Header.module.css';

const HEADER_EXCLUDED_PAGES = [ROUTES.demo, ROUTES.register, ROUTES.login];

const ADMIN_NAVIGATION = [
    { to: ROUTES.library, text: 'navbar.tracks' },
    { to: ROUTES.podcasts, text: 'navbar.podcasts' },
    { to: ROUTES.users, text: 'navbar.users' },
    { to: ROUTES.languages, text: 'navbar.languages' },
    { to: ROUTES.dictionary, text: 'navbar.dictionary' },
    { to: ROUTES.situations, text: 'navbar.situations' },
    { to: ROUTES.healthcheck, text: 'navbar.services' },
    { to: ROUTES.notify, text: 'navbar.notify' },
];

export const TEACHER_NAVIGATION = [
    { to: ROUTES.lessons, text: 'navbar.lessons' },
    { to: ROUTES.students, text: 'navbar.students' },
    { to: ROUTES.teacherExercises, text: 'navbar.exercises' },
    { to: ROUTES.teacherTracks, text: 'navbar.tracks' },
    { to: ROUTES.teacherSituations, text: 'navbar.situations' },
    { to: ROUTES.teacherDictionary, text: 'navbar.dictionaries' },
    { to: ROUTES.teachersList, text: 'navbar.teachers' },
];

const Header = () => {
    const { auth } = useContext(AuthContext);
    const { appInterface } = useContext(InterfaceContext);

    const navigate = useNavigate();
    const [notifyValue, setNotifyValue] = useState('');
    const location = useLocation();
    const { t, i18n } = useTranslation();

    const { language } = i18n;

    const { notifications } = studentsStore;
    const { availableLanguages } = appInterface;

    const logout = () => {
        auth.logout(navigate);
    };

    useEffect(() => {
        if (!notifications.length) {
            setNotifyValue('');
            return;
        }

        const total = notifications.map((n) => n.exercises).flat().length;
        setNotifyValue(total > 100 ? '99+' : total.toString());
    }, [notifications, language]);

    const isHeaderVisible =
        !HEADER_EXCLUDED_PAGES.includes(location.pathname) &&
        !location.pathname.includes(ROUTES.share_situation) &&
        !location.pathname.includes(ROUTES.share_dictionary);

    const isLogin = location.pathname.includes(ROUTES.login);
    const isRegister = location.pathname.includes(ROUTES.register);
    const isGuest = auth.role === Roles.Guest;
    const isOnboarding = onboardingStore.isOnboarding(OnboardingKeys.Start);

    const handleSignInButton = () => {
        auth.setAuthMode(AuthModes.Login);
        auth.setIsAuth(true);
    };

    if (!isHeaderVisible) {
        return null;
    }

    if (auth.role === Roles.Teacher) {
        return (
            <header className={cl.teacherHeader}>
                <div className={cl.teacherHeaderInner}>
                    <TeacherLink
                        to={ROUTES.teachersLanding}
                        style={{
                            display: 'flex',
                            gap: 2,
                            marginLeft: 4,
                            marginTop: 5,
                        }}
                    >
                        <TeacherLogo className={cl.teacherLogo} />
                    </TeacherLink>
                    {!isOnboarding && (
                        <>
                            <OnboardingWrapper
                                onboardingKey={
                                    onboardingStore.isOnboarding(
                                        OnboardingKeys.StudentsNavigation
                                    ) ||
                                    onboardingStore.isOnboarding(
                                        OnboardingKeys.StudentsKanban
                                    )
                                }
                                title={t(
                                    onboardingStore.isOnboarding(
                                        OnboardingKeys.StudentsNavigation
                                    )
                                        ? 'teacher_onboarding.students_navigation'
                                        : 'teacher_onboarding.students_kanban'
                                )}
                                subtitle={t(
                                    'teacher_onboarding.press_to_continue'
                                )}
                                delay={
                                    onboardingStore.isOnboarding(
                                        OnboardingKeys.StudentsNavigation
                                    )
                                        ? 100
                                        : 1200
                                }
                                bubblePosition={{
                                    x: BubblePositions.x.LeftBorder,
                                    y: BubblePositions.y.Bottom,
                                }}
                                arrowPosition={ArrowPositions.Bottom}
                            >
                                <div>
                                    <TeacherLink
                                        to={ROUTES.students}
                                        text={t('navbar.students')}
                                        notifyValue={notifyValue}
                                    />
                                </div>
                            </OnboardingWrapper>

                            <OnboardingWrapper
                                onboardingKey={onboardingStore.isOnboarding(
                                    OnboardingKeys.LessonsNavigation
                                )}
                                title={t('teacher_onboarding.to_kanban')}
                                subtitle={t(
                                    'teacher_onboarding.press_to_continue'
                                )}
                                delay={1000}
                                bubblePosition={{
                                    x: BubblePositions.x.Center,
                                    y: BubblePositions.y.Bottom,
                                }}
                                arrowPosition={ArrowPositions.Bottom}
                            >
                                <div>
                                    <TeacherLink
                                        to={ROUTES.lessons}
                                        text={t('navbar.lessons')}
                                    />
                                </div>
                            </OnboardingWrapper>

                            <OnboardingWrapper
                                onboardingKey={onboardingStore.isOnboarding(
                                    OnboardingKeys.ContentNavigation
                                )}
                                title={t(
                                    'teacher_onboarding.content_info_title'
                                )}
                                subtitle={t(
                                    'teacher_onboarding.content_info_subtitle'
                                )}
                                delay={1000}
                                bubblePosition={{
                                    x: BubblePositions.x.Center,
                                    y: BubblePositions.y.Bottom,
                                }}
                                showButton
                            >
                                <div>
                                    <NavigationSelect />
                                </div>
                            </OnboardingWrapper>
                        </>
                    )}
                    <div className={cl.leftPart}>
                        {!isOnboarding && (
                            <OnboardingWrapper
                                onboardingKey={onboardingStore.isOnboarding(
                                    OnboardingKeys.TeachersNavigation
                                )}
                                title={t('teacher_onboarding.teachers_info')}
                                subtitle={t(
                                    'teacher_onboarding.teachers_info_subtitle'
                                )}
                                delay={1000}
                                bubblePosition={{
                                    x: BubblePositions.x.RightBorder,
                                    y: BubblePositions.y.Bottom,
                                }}
                                bubbleStyle={{
                                    width: 'max-content',
                                }}
                                showButton
                            >
                                <div
                                    style={{
                                        marginLeft: 'auto',
                                        marginRight: 10,
                                    }}
                                >
                                    <TeacherLink
                                        onClick={() =>
                                            teachersStore.setCurrentTeacher(
                                                null
                                            )
                                        }
                                        to={ROUTES.teachersList}
                                        text={t('navbar.teachers')}
                                    />
                                </div>
                            </OnboardingWrapper>
                        )}
                        <div className={cl.teacherProfileBtns}>
                            <div className={cl.currentLanguage}>
                                {language.toUpperCase()}
                            </div>
                            <TeacherLink to={ROUTES.profile}>
                                <IconTeacherProfile />
                            </TeacherLink>
                        </div>
                    </div>
                </div>
            </header>
        );
    }

    return (
        <header className={cl.header}>
            <div className={cl.headerInner}>
                <BackPageArrow />
                <TeacherLink
                    to={ROUTES.teachersLanding}
                    style={{
                        minWidth: isLogin || isRegister ? 100 : 50,
                        display: 'flex',
                        gap: 2,
                        marginLeft: 4,
                        marginTop: 5,
                    }}
                >
                    <TeacherLogo className={cl.teacherLogo} />
                    {(isLogin || isRegister || isGuest) && (
                        <TeacherLogoText className={cl.teacherLogoText} />
                    )}
                </TeacherLink>
                {auth.role === Roles.Admin && (
                    <>
                        {ADMIN_NAVIGATION.map((l, i) => (
                            <StyledNavLink
                                key={l.to}
                                to={l.to}
                                text={t(l.text)}
                                showDivider={i !== ADMIN_NAVIGATION.length - 1}
                                showUnderline
                            />
                        ))}
                    </>
                )}
                {[Roles.Admin, Roles.Teacher, Roles.Marketing].includes(
                    auth.role
                ) && (
                    <>
                        <div className={cl.profileBtns}>
                            <StyledNavLink to={ROUTES.profile}>
                                <PrimaryButton
                                    variant={'dark'}
                                    icon={
                                        <IconProfile
                                            style={{
                                                height: 20,
                                                width: 20,
                                                minWidth: 20,
                                            }}
                                        />
                                    }
                                    text={
                                        auth.user?.name ||
                                        auth.user?.email?.split('@')[0]
                                    }
                                    style={{ padding: '8px 24px' }}
                                />
                            </StyledNavLink>
                            {auth.role === Roles.Teacher && (
                                <div className={cl.currentLanguage}>
                                    {language.toUpperCase()}
                                </div>
                            )}
                            <PrimaryButton
                                variant={'dark'}
                                icon={<IconLogout />}
                                onClick={logout}
                                style={{
                                    width: 36,
                                    height: 36,
                                    padding: 'unset',
                                }}
                            />
                        </div>
                    </>
                )}
                {isGuest && (
                    <TeacherLink
                        style={{ marginLeft: 'auto' }}
                        text={t('Sign In')}
                        notifyValue={notifyValue}
                        onClick={handleSignInButton}
                    />
                )}
                {(isLogin || isRegister) && (
                    <Select
                        label={t('profile.interface_language')}
                        variant={'grey'}
                        value={language}
                        options={availableLanguages}
                        onChange={appInterface.setLanguage}
                        style={{ marginLeft: 'auto', minWidth: 200 }}
                        hideReset
                    />
                )}
            </div>
            <AuthModal />
        </header>
    );
};

export const TeacherLink = ({
    to,
    style,
    children,
    text,
    notifyValue,
    onClick,
}) => {
    const handleClick = (e) => {
        onClick && onClick();
    };

    return (
        <NavLink
            className={cl.teacherLink}
            to={to}
            style={style}
            onClick={handleClick}
        >
            {({ isActive }) => (
                <>
                    {text ? (
                        <Button
                            variant={isActive ? 'grey' : 'white'}
                            text={text}
                        />
                    ) : (
                        children
                    )}
                    {notifyValue && (
                        <div className={cl.notification}>{notifyValue}</div>
                    )}
                </>
            )}
        </NavLink>
    );
};

export default observer(Header);
