import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import AdjustableTextarea from '../../../../../../../../components/UI/AdjustableTextarea/AdjustableTextarea';
import InputLabel from '../../../../../../../../components/UI/InputLabel/InputLabel';
import { Button, Checkbox } from '../../../../../../../../teacherComponents';
import { QuestionsLabel } from '../../../Preview/components';
import AddButton from '../../../../../../../../components/UI/AddButton/AddButton';
import cl from './QuizEditor.module.css';

const OptionCard = ({
    option,
    questionId,
    isCorrect,
    onClick,
    onChange,
    onDelete,
}) => {
    const { t } = useTranslation();

    return (
        <div className={cl.answersCont}>
            <div className={cl.answerInner}>
                <div className={cl.answerInput}>
                    <AdjustableTextarea
                        className={cl.option}
                        placeholder={t('exercises.add_answer')}
                        value={option?.text}
                        onChange={(text) =>
                            onChange(questionId, option?.id, text)
                        }
                        onDelete={() => onDelete(questionId, option?.id)}
                        isFocus={!option?.text?.length}
                        mode={'sentence'}
                    />
                </div>
                <Checkbox
                    value={isCorrect}
                    onChange={() => {
                        onClick(questionId, option?.id);
                    }}
                />
            </div>
        </div>
    );
};

const QuizEditor = ({ exerciseData, disableSave, onChange }) => {
    const { t } = useTranslation();
    const questions = exerciseData.questions || [];

    const [isEmptyQuestion, setIsEmptyQuestion] = useState(false);
    const [isInvalidQuestion, setIsInvalidQuestion] = useState(false);
    const [isEmptyAnswer, setIsEmptyAnswer] = useState(false);

    const handleQuestionAdd = () => {
        onChange({
            questions: [...questions, { id: uuid(), text: '', options: [] }],
        });
    };

    const handleQuestionChange = (questionId, text) => {
        onChange({
            questions: questions.map((q) =>
                q.id === questionId ? { ...q, text } : q
            ),
        });
    };

    const handleQuestionDelete = (questionId) => {
        onChange({
            questions: questions.filter((q) => q.id !== questionId),
        });
    };

    const handleOptionAdd = (questionId) => {
        onChange({
            questions: questions.map((q) =>
                q.id === questionId
                    ? {
                          ...q,
                          options: [...q.options, { id: uuid(), text: '' }],
                      }
                    : q
            ),
        });
    };

    const handleOptionChange = (questionId, optionId, text) => {
        onChange({
            questions: questions.map((q) =>
                q.id === questionId
                    ? {
                          ...q,
                          answer:
                              q.options.find((o) => o.id === optionId)?.text ===
                              q.answer
                                  ? text
                                  : q.answer,
                          options: q.options.map((o) =>
                              o.id === optionId ? { ...o, text } : o
                          ),
                      }
                    : q
            ),
        });
    };

    const handleOptionDelete = (questionId, optionId) => {
        onChange({
            questions: questions.map((q) =>
                q.id === questionId
                    ? {
                          ...q,
                          options: q.options.filter((o) => o.id !== optionId),
                      }
                    : q
            ),
        });
    };

    const handleAnswerChange = (id, optionId) => {
        onChange({
            questions: questions.map((q) =>
                q.id === id
                    ? {
                          ...q,
                          answer: q.options.find((o) => o.id === optionId)
                              ?.text,
                      }
                    : q
            ),
        });
    };

    useEffect(() => {
        if (!questions.length) {
            setIsEmptyQuestion(false);
            return;
        }
        if (questions[questions.length - 1]) {
            if (!questions[questions.length - 1].text.length) {
                setIsEmptyQuestion(true);
                setIsEmptyAnswer(true);
            } else {
                setIsEmptyQuestion(false);
            }
            const answersLength =
                questions[questions.length - 1]?.options?.length;
            if (!answersLength) {
                setIsEmptyAnswer(false);
                setIsInvalidQuestion(true);
                return;
            }
            setIsInvalidQuestion(
                !questions[questions.length - 1]?.answer?.length
            );
            setIsEmptyAnswer(!questions[questions.length - 1]?.answer?.length);
        }
    }, [questions]);

    useEffect(() => {
        disableSave(isEmptyAnswer || isEmptyQuestion || isInvalidQuestion);
    }, [isEmptyAnswer, isEmptyQuestion, isInvalidQuestion]);

    return (
        <div className={cl.quizEditor}>
            <QuestionsLabel label={'edit_questions_answers'} />
            {questions?.length ? (
                questions.map((q, qIdx) => (
                    <div className={cl.questionCont} key={q.id}>
                        <p className={cl.questionNumber}>{`${qIdx + 1}.`}</p>
                        <AdjustableTextarea
                            className={cl.question}
                            placeholder={t('exercises.add_question')}
                            value={q.text}
                            onChange={(text) =>
                                handleQuestionChange(q.id, text)
                            }
                            onDelete={() => handleQuestionDelete(q.id)}
                            isFocus={!q.text.length}
                            mode={'sentence'}
                        />
                        {q.options.length ? (
                            <div className={cl.answers}>
                                {q.options.map((o) => (
                                    <OptionCard
                                        key={o.id}
                                        option={o}
                                        questionId={q.id}
                                        isCorrect={q.answer === o.text}
                                        onChange={handleOptionChange}
                                        onDelete={handleOptionDelete}
                                        onClick={handleAnswerChange}
                                    />
                                ))}
                            </div>
                        ) : (
                            <></>
                        )}
                        <AddButton
                            text={t(
                                q.options.length
                                    ? 'exercises.new_answer'
                                    : 'exercises.first_answer'
                            )}
                            isDisabled={isEmptyAnswer}
                            onClick={() => handleOptionAdd(q.id)}
                        />
                    </div>
                ))
            ) : (
                <InputLabel text={t('exercises.no_questions')} />
            )}
            <AddButton
                text={t(
                    questions.length
                        ? 'exercises.new_question'
                        : 'exercises.first_question'
                )}
                isDisabled={isEmptyQuestion}
                onClick={handleQuestionAdd}
            />
        </div>
    );
};

export default QuizEditor;
