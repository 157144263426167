import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import player from '../../store/player';
import cl from './YoutubePlayer.module.css';
import exercisesStore from '../../pages/TeacherContent/store/exercisesStore';
const YTPlayer = require('yt-player');
const TICKS_IN_SECOND = 10000000;

const YoutubePlayer = () => {
    const ref = useRef(null);

    const { chaptersModalType, view } = exercisesStore;

    useEffect(() => {
        ref.current = new YTPlayer('#YTPlayer', {
            captions: false,
            controls: false,
            modestBranding: true,
            timeupdateFrequency: 100,
            playsInline: true,
            fullscreen: false,
            annotations: false,
            rel: 0,
            related: true,
            disablekb: 0,
            iv_load_policy: 3,
            showinfo: 0,
            enablejsapi: 1,
        });
        onPlayerReady();
        return () => {
            player.playerRef?.destroy();
            ref.current?.destroy();
            player.setPlayerRef(null);
            player.setIsPlaying(false);
        };
    }, [chaptersModalType, view]);

    function onPlayerReady() {
        ref.current.load(
            player.ytVideoId,
            false,
            player.currentTime / TICKS_IN_SECOND
        );

        ref.current.on('paused', (e) => {
            player.setIsPlaying(false);
        });
        ref.current.on('ended', () => {
            player.setIsPlaying(false);
        });
        ref.current.on('playing', () => {
            player.setIsPlaying(true);
        });
        ref.current.off('timeupdate', handleOnProgress);
        ref.current.on('timeupdate', handleOnProgress);
        ref.current.seek(player.currentTime / TICKS_IN_SECOND);
        player.setPlayerRef(ref.current);
        player.setIsPlaying(false);
    }

    function handleOnProgress(time) {
        if (time === 0) return;
        if (
            player.isRecognizing &&
            player.maxTime &&
            time * TICKS_IN_SECOND > player.maxTime
        ) {
            player.handleChangeAudioTime(player.maxTime, true);
            player.playerRef.pause();
            return false;
        } else {
            player.handleChangeAudioTime(time);
        }
    }

    return (
        <div className={cl.container}>
            <div className={cl.ytContainer} id="YTPlayer" />
        </div>
    );
};

export default observer(YoutubePlayer);
