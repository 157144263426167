import { makeAutoObservable } from 'mobx';
import UserService from '../api/UserService';
import player from './player';
import { Roles } from '../data/common';
import { ROUTES } from '../router';
import { PendingWordsKey } from '../pages/TeacherContent/views/DictionaryView/data/constants';

export const AuthModes = {
    Login: 'login',
    Register: 'register',
};

// TODO: temporary solution. remove or refactor after marketing research @saratovkin
const MarketingEmail = 'marketing@thepony.app';
const DefaultUserLanguage = 'en';

const userMapper = (data) => ({
    email: data.email,
    id: data.id,
    isActivated: true,
    isBlocked: data.isBanned,
    sex: data.sex,
    lang: data.lang || DefaultUserLanguage,
    languages: [data.lang || DefaultUserLanguage],
    name: data.name,
    role: data.role || Roles.User,
    roles: [data.role || Roles.User],
});

export default class Auth {
    user = {};
    role = Roles.Guest;
    isAuth = false;
    authMode = AuthModes.Login;

    constructor() {
        makeAutoObservable(this);
    }

    setAuthMode(authMode) {
        this.authMode = authMode;
    }

    setRole(role) {
        this.role = role;
    }

    setIsAuth(flag) {
        this.isAuth = flag;
    }

    setUser(data) {
        const user = userMapper(data);
        // TODO: temporary solution. remove or refactor after marketing research @saratovkin
        this.setRole(
            user.email === MarketingEmail ? Roles.Marketing : user.role
        );
        player.setTargetLanguage(user.lang);
        this.user = user;
    }

    async checkAuth(navigate) {
        const res = await UserService.getMe();
        if (res.status === 200) {
            const { data } = res;
            if (data.role === Roles.User) {
                this.logout();
                return;
            }
            if (!data.id) return;
            this.setUser(data);
        }
    }

    async logout(navigate) {
        await UserService.logout();
        localStorage.setItem(PendingWordsKey, '[]');
        this.setUser({ lang: this.user?.lang, role: Roles.Guest });
        navigate(ROUTES.login);
    }

    async login(email, password, navigate) {
        const res = await UserService.login(email, password);
        this.setIsAuth(false);
        const { data } = res;
        if (data.role === Roles.User) {
            this.logout();
            return;
        }
        if (res.status === 204) {
            this.checkAuth(navigate);
        }
    }
}
