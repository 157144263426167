import React from 'react';
import { useLocation } from 'react-router';
import { auth } from '../../../App';
import { Roles } from '../../../data/common';
import cl from './AppWrapper.module.css';
import { ROUTES } from '../../../router';

const WRAPPER_EXCLUDED_PAGES = ['/', ROUTES.register];

const AppWrapper = ({ children }) => {
    const location = useLocation();
    const { role } = auth;

    const hideWrapper = WRAPPER_EXCLUDED_PAGES.includes(location.pathname);
    const teachersLanding = ROUTES.teachersLanding.includes(location.pathname);

    if (hideWrapper) return <>{children}</>;

    return (
        <div
            className={`${cl.AppWrapper} ${
                role === Roles.Teacher ? cl.teacher : ''
            } ${teachersLanding ? cl.teachersLanding : ''}`}
            id={'appWrapper'}
        >
            {children}
        </div>
    );
};

export default AppWrapper;
