import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { AuthContext } from '../../App';
import { Login, Register } from '../../pages';
import Modal from '../UI/Modal/Modal';
import cl from './AuthModal.module.css';
import { AuthModes } from '../../store/auth';

const AuthModal = () => {
    const { auth } = useContext(AuthContext);
    const { isAuth, authMode } = auth;

    return (
        <Modal
            visible={isAuth}
            setVisible={() => auth.setIsAuth(false)}
            className={cl.authModal}
            addBlur
        >
            {isAuth && (
                <>{authMode === AuthModes.Login ? <Login /> : <Register />}</>
            )}
        </Modal>
    );
};

export default observer(AuthModal);
