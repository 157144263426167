import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import situationsStore from '../../store/situationsStore';
import { useTranslation } from 'react-i18next';
import SituationService from '../../../../api/SituationsService';
import { useFetching } from '../../../../hooks/useFetching';
import { LEVELS } from '../../data/constants';
import { BackButton, NavigationWrapper } from '..';
import {
    Checkbox,
    PrimaryButton,
    PrimarySelect,
    ToggleButton,
} from '../../../../UI';
import { ReactComponent as IconClose } from '../../../../assets/img/icon-cross.svg';
import { ReactComponent as IconEdit } from '../../../../assets/img/icon-edit.svg';
import cl from './SituationTitle.module.css';

const SituationTitle = ({
    id,
    processed,
    levels,
    title,
    onChange,
    isLoading,
}) => {
    const { t } = useTranslation();

    const { backBtnDisabled, categories, language } = situationsStore;

    const [isEdit, setIsEdit] = useState(false);
    const [newLevels, setNewLevels] = useState(levels);
    const [currentCategoryId, setCurrentCategoryId] = useState();
    const [categoryOptions, setCategoryOptions] = useState([]);

    const [updateLevels, levelsLoading] = useFetching(async () => {
        const res = await SituationService.updateLevels({
            id,
            levels: newLevels,
            language,
        });
        if (res.status === 200) {
            setIsEdit(false);
            onChange(newLevels);
        }
    });

    const [updateCategory] = useFetching(async (newId) => {
        const { data } = await SituationService.updateSituationCategory({
            id,
            categoryId: newId,
        });
        if (data && data.categoryId) {
            setCurrentCategoryId(data.categoryId);
            situationsStore.setWasChanged(true);
        }
    });

    const [updateProcessed] = useFetching(async () => {
        const res = await SituationService.updateSituationProcessed({
            id,
            processed: !processed,
            language,
        });
        if (res?.status === 200) {
            situationsStore.setCurrentSituation({
                ...situationsStore.currentSituation,
                processed: !processed,
            });
        }
    });

    const handleSituationClose = () => {
        situationsStore.setCurrentSituation(null);
    };

    const displayButtonText = () => {
        if (situationsStore.currentCategory)
            return situationsStore.currentCategory.title;
        return 'situations.categories_btn';
    };

    useEffect(() => {
        setIsEdit(false);
        setNewLevels(levels);
    }, [id, levels]);

    useEffect(() => {
        if (!isEdit) setNewLevels(levels);
    }, [isEdit]);

    useEffect(() => {
        updateCategory();
    }, [currentCategoryId]);

    useEffect(() => {
        const options = categories.map((c) => ({ name: c.title, value: c.id }));
        setCategoryOptions(options);
        const currentCategory = categories.find((o) =>
            o.situations?.some((s) => s.id === id)
        );
        if (currentCategory) {
            setCurrentCategoryId(currentCategory.id);
        }
    }, [categories]);

    return (
        <div className={cl.container}>
            <NavigationWrapper style={{ justifyContent: 'space-between' }}>
                <BackButton
                    style={{ position: 'static' }}
                    onClick={handleSituationClose}
                    text={displayButtonText()}
                    isDisabled={backBtnDisabled}
                />
                <>
                    {isEdit ? (
                        <div className={cl.levelOptions}>
                            <PrimaryButton
                                variant={'dark'}
                                icon={<IconClose className={cl.closeIcon} />}
                                onClick={() => setIsEdit(false)}
                                style={{ padding: '9.5px 11px' }}
                            />
                            <ToggleButton
                                options={LEVELS}
                                selectedOption={newLevels}
                                setOption={setNewLevels}
                                variant={'round'}
                                multiple
                            />
                            <PrimaryButton
                                variant={'dark'}
                                text={t('buttons.save')}
                                onClick={updateLevels}
                                isLoading={levelsLoading}
                                isDisabled={!newLevels.length}
                            />
                        </div>
                    ) : (
                        <div
                            className={cl.titleCont}
                            onClick={() => setIsEdit(true)}
                        >
                            <p className={cl.situationTitle}>
                                {title}
                                {!isLoading && (
                                    <span className={cl.situationTitle}>
                                        {levels.length
                                            ? ` (${levels.join(', ')})`
                                            : ''}
                                    </span>
                                )}
                            </p>
                            <IconEdit className={cl.editIcon} />
                        </div>
                    )}
                </>
                {!isEdit && (
                    <Checkbox
                        label={t('podcasts.processed')}
                        value={processed}
                        onChange={updateProcessed}
                    />
                )}
                <div className={cl.selectCategoryCont}>
                    <p className={cl.selectLabel}>
                        {t('situations.change_category')}
                    </p>
                    <PrimarySelect
                        value={currentCategoryId}
                        options={categoryOptions}
                        onChange={updateCategory}
                        style={{ width: 300, minWidth: 300 }}
                    />
                </div>
            </NavigationWrapper>
        </div>
    );
};

export default observer(SituationTitle);
