import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import exercisesStore from '../../../../../../store/exercisesStore';
import AddButton from '../../../../../../../../components/UI/AddButton/AddButton';
import CloseButton from '../../../../../../../../components/UI/CloseButton/CloseButton';
import { GrammarExerciseTypes } from '../../../../../../data/constants';
import cl from './GapWord.module.css';

const MIN_OPTIONS_AMOUNT = 2;

const Input = ({
    value,
    placeholder,
    onChange,
    isDelete,
    onDelete,
    isDisabled,
    isFocus,
}) => {
    const inputRef = useRef(null);
    const [isBlur, setIsBlur] = useState(true);

    useEffect(() => {
        if (isFocus) {
            inputRef.current.focus();
        }
    }, []);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            inputRef.current.blur();
        }
    };

    const handleBlur = () => {
        if (!value.length && isDelete) {
            onDelete();
        }
        setIsBlur(true);
    };

    return (
        <div className={cl.inputCont}>
            <input
                ref={inputRef}
                placeholder={placeholder}
                value={value}
                type="text"
                className={`${cl.input} ${isBlur ? cl.blur : cl.focus} ${
                    isDisabled ? cl.disabled : ''
                }`}
                onChange={(e) => onChange(e.target.value, e)}
                onKeyDown={handleKeyDown}
                onFocus={() => setIsBlur(false)}
                onBlur={handleBlur}
            />
            {isDelete && (
                <div className={`${cl.deleteIcon} ${isBlur ? '' : cl.focus}`}>
                    <CloseButton
                        style={{
                            width: 10,
                            height: 10,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            right: 6,
                        }}
                        onClick={onDelete}
                    />
                </div>
            )}
        </div>
    );
};

const GapWord = ({ word, onChange }) => {
    const { t } = useTranslation();

    const { currentExercise } = exercisesStore;
    const { type } = currentExercise;

    const wordRef = useRef();
    const [isEdit, setIsEdit] = useState(false);
    const [options, setOptions] = useState(word.options || []);

    const getClassName = () => {
        const classnames = [cl.gap];
        if (type === GrammarExerciseTypes.Multichoice && options.length > 1)
            classnames.push(cl.selected);
        if (type === GrammarExerciseTypes.Cloze && word.hidden)
            classnames.push(cl.selected);
        if (type === GrammarExerciseTypes.Substitution && word.hidden)
            classnames.push(cl.selected);
        if (isEdit) {
            classnames.push(cl.active);
        }
        return classnames.join(' ');
    };

    const renderTooltip = () => {
        if (type !== GrammarExerciseTypes.Multichoice || !isEdit) return <></>;

        const rest = options.filter((o) => o !== word.word);
        const isAddDisabled = rest.length && !rest[rest.length - 1];

        return (
            <span className={cl.tooltip}>
                <div
                    className={cl.tooltipCont}
                    onClick={(e) => e.stopPropagation()}
                >
                    <span>{t('exercises.tooltip_dropdown')}</span>
                    {options.map((option, idx) => (
                        <div
                            className={cl.optionLine}
                            key={`${word.id}_cont_${idx}`}
                        >
                            <Input
                                key={`${word.id}_option_${idx}`}
                                value={option}
                                onChange={(text) =>
                                    handleOptionInput(idx, text)
                                }
                                isDelete={options.length < MIN_OPTIONS_AMOUNT}
                                isDisabled={option === word.word}
                                onDelete={() => handleOptionDelete(idx)}
                                isFocus={!option.length}
                            />
                        </div>
                    ))}
                    <div className={cl.buttons}>
                        <AddButton
                            text={t('exercises.tooltip_new_option')}
                            onClick={handleNewOption}
                            isDisabled={isAddDisabled}
                        />
                        {options.length !== 0 && (
                            <p
                                className={cl.unselectButton}
                                onClick={handleOptionsClear}
                            >
                                {t('exercises.unselect_word')}
                            </p>
                        )}
                    </div>
                </div>
            </span>
        );
    };

    const handleOptionInput = (index, text) => {
        const newOptions = [...options];
        newOptions[index] = text.trim();
        setOptions(newOptions);
    };

    const handleNewOption = () => {
        const newOptions = options.length ? [...options, ''] : [word.word, ''];
        setOptions(newOptions);
    };

    const handleOptionDelete = (index) => {
        const newOptions = [...options];
        newOptions.splice(index, 1);
        setOptions(newOptions);
    };

    const handleWordClick = () => {
        if (type === GrammarExerciseTypes.Multichoice) {
            setIsEdit(!isEdit);
            return;
        }
        onChange(options);
    };

    const handleOptionsClear = () => {
        setOptions([]);
        setIsEdit(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (!isEdit) return;
            if (wordRef.current && !wordRef.current.contains(event.target)) {
                onChange(options);
                setIsEdit(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wordRef, isEdit, options]);

    useEffect(() => {
        setOptions([...new Set(options)]);
    }, [isEdit]);

    return (
        <div ref={wordRef}>
            <span className={getClassName()} onClick={handleWordClick}>
                {word.word}
                {renderTooltip()}
            </span>
            {word.endChar && (
                <span style={{ userSelect: 'none' }}>{word.endChar}</span>
            )}
        </div>
    );
};

export default observer(GapWord);
