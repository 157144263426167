import React from 'react';
import GapWord from '../GapWord/GapWord';
import cl from './MultichoiceEditor.module.css';
import { QuestionsLabel } from '../../../Preview/components';

const MultichoiceEditor = ({ exerciseData, onChange }) => {
    const { sentences } = exerciseData;

    const handleWordChange = (sentenceId, wordId, options) => {
        onChange({
            sentences: sentences?.map((s) =>
                s.id === sentenceId
                    ? {
                          ...s,
                          words: s?.words?.map((w) =>
                              w.id === wordId ? { ...w, options } : w
                          ),
                      }
                    : s
            ),
        });
    };

    return (
        <div className={cl.textCont}>
            <QuestionsLabel label={'gap_word_select'} />

            <div className={cl.exerciseText}>
                <div className={cl.textInner}>
                    {sentences?.map((s) => {
                        return (
                            <div key={s.id} className={cl.sentence}>
                                {s.words?.map((word) => (
                                    <GapWord
                                        key={word.id}
                                        sentenceId={s.id}
                                        word={word}
                                        onChange={(options) =>
                                            handleWordChange(
                                                s.id,
                                                word.id,
                                                options
                                            )
                                        }
                                    />
                                ))}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default MultichoiceEditor;
