import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import SupportModal from './SupportModal/SupportModal';

const Footer = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const [showSupportModal, setShowSupportModal] = useState(false);
    let showOnPages = [];
    let isShow = false;

    location.pathname
        .slice(1)
        .split('/')
        .forEach((path) => {
            showOnPages.forEach((page) => {
                if (page === path) {
                    isShow = true;
                    return;
                }
            });
        });

    if (!isShow) {
        return null;
    }

    return (
        <div
            style={{
                width: '100%',
                maxWidth: 1440,
                margin: '0 auto',
                marginTop: 'auto',
                padding: 20,
                display: 'flex',
                alignItems: 'center',
                borderTop: '1px solid #231F20',
                justifyContent: 'space-between',
            }}
        >
            <SupportModal
                showSupportModal={showSupportModal}
                setShowSupportModal={setShowSupportModal}
            />
            <p
                style={{
                    lineHeight: '17px',
                    fontSize: 13,
                    color: 'black',
                    maxWidth: 560,
                    fontWeight: 300,
                }}
            >
                {t('footer.copyrights')}
            </p>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Link
                    style={{
                        lineHeight: '17px',
                        fontSize: 13,
                        color: 'black',
                        textAlign: 'right',
                        textDecoration: 'underline',
                        fontWeight: 300,
                    }}
                    onClick={() => setShowSupportModal(!showSupportModal)}
                    to={'#'}
                >
                    {t('footer.support')}
                </Link>
                <Link
                    style={{
                        lineHeight: '17px',
                        fontSize: 13,
                        color: 'black',
                        textAlign: 'right',
                        textDecoration: 'underline',
                        fontWeight: 300,
                    }}
                    to={'#'}
                >
                    {t('footer.terms_and_confidentiality')}
                </Link>
            </div>
        </div>
    );
};

export default Footer;
