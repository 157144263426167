import React from 'react';
import { ReactComponent as IconClose } from '../../assets/img/icon-close.svg';
import cl from './Chip.module.css';

const Chip = ({ text, onDelete }) => {
    return (
        <div className={cl.chip}>
            <span>{text}</span>
            {onDelete && (
                <IconClose
                    style={{ cursor: 'pointer', width: 12, height: 12 }}
                    onClick={onDelete}
                />
            )}
        </div>
    );
};

export default Chip;
