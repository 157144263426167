import React from 'react';
import { PrimaryButton } from '../';
import { ReactComponent as IconRefresh } from '../../assets/img/icon-refresh.svg';

const RefreshButton = ({ isLoading, onClick, style }) => {
    return (
        <PrimaryButton
            isLoading={isLoading}
            icon={isLoading ? null : <IconRefresh />}
            variant={'dark'}
            style={{
                width: 38,
                height: 38,
                minWidth: 38,
                padding: 'unset',
                ...style,
            }}
            onClick={onClick}
        />
    );
};

export default RefreshButton;
