import React from 'react';
import { useTranslation } from 'react-i18next';
import { StudentCardModal } from '..';
import {
    ArrowPositions,
    BubblePositions,
    OnboardingKeys,
    OnboardingWrapper,
} from '../../../../teacherComponents/Onboarding';
import onboardingStore from '../../../../store/onboarding';
import cl from './StudentCard.module.css';

const StudentCard = ({
    student,
    notifications,
    isActive,
    setCurrent,
    onDelete,
    onChange,
}) => {
    const { t } = useTranslation();
    const { name, level, profilePic } = student;

    const onboardingKey = onboardingStore.isOnboarding(
        OnboardingKeys.AvailableColumn
    );

    const renderNotification = () => {
        if (!notifications || !notifications?.exercises?.length) return null;
        return (
            <div className={cl.notification}>
                {notifications.exercises.length}
            </div>
        );
    };

    const handleCardClick = () => {
        if (isActive) return;
        setCurrent && setCurrent(student);
    };

    return (
        <div
            className={`${cl.studentCard} ${isActive ? cl.active : ''}`}
            onClick={handleCardClick}
        >
            <div className={cl.cardInner}>
                <div className={cl.profilePic}>
                    <p>{profilePic}</p>
                </div>
                <div>
                    <div className={cl.titleCont}>
                        <p className={cl.name}>{name}</p>
                        {renderNotification()}
                    </div>
                    <OnboardingWrapper
                        onboardingKey={onboardingStore.isOnboarding(
                            OnboardingKeys.AvailableColumn
                        )}
                        subtitle={t('teacher_onboarding.sort_lessons_info')}
                        delay={1000}
                        showBlur={false}
                        bubblePosition={{
                            x: BubblePositions.x.LeftBorder,
                            y: BubblePositions.y.Top,
                        }}
                        bubbleStyle={{
                            maxWidth: 250,
                            background: '#bababad5',
                        }}
                        arrowPosition={ArrowPositions.Top}
                    >
                        <p
                            className={
                                onboardingKey ? cl.onboardingLevel : cl.level
                            }
                        >
                            {level}
                        </p>
                    </OnboardingWrapper>
                </div>
                {isActive && (
                    <StudentCardModal
                        student={student}
                        onDelete={onDelete}
                        onChange={onChange}
                    />
                )}
            </div>
        </div>
    );
};

export default StudentCard;
