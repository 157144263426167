import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import player from '../../../../store/player';
import { Sentence } from '..';
import getTopCenterAndOffset from '../../../../utils/getTopCenterAndOffset';
import useWindowSize from '../../../../hooks/useWindowSize';
import cl from './TextDisplay.module.css';

const TextDisplay = ({ textRef, onScroll }) => {
    const [dotPosTop, setDotPosTop] = useState(null);
    const [needScrollDot, setNeedScrollDot] = useState(false);
    const outputDisplayStats = useRef({});
    const lastP = useRef(null);
    const [width, height] = useWindowSize();
    const { textArr } = player;

    const calculateDotPos = (p) => {
        if (!p?.current) {
            p = { current: lastP?.current };
        } else {
            lastP.current = p.current;
        }
        if (!p.current) return;
        const outputDisplayH = textRef.current.scrollHeight;
        const clientHeight = textRef.current.clientHeight;
        if (player.textArr.length < 3) {
            setNeedScrollDot(false);
        } else if (outputDisplayH <= clientHeight) {
            setNeedScrollDot(false);
            return;
        } else {
            setNeedScrollDot(true);
        }
        const coords = getTopCenterAndOffset(p.current);
        outputDisplayStats.current = getTopCenterAndOffset(textRef.current);
        const outputDisplayCoords = outputDisplayStats.current;
        const scrollTop = textRef.current.scrollTop;
        let dotPos = coords.top - outputDisplayCoords.top + scrollTop;
        const percent = (dotPos / outputDisplayH) * 100;

        setDotPosTop(percent);

        if (!window.autoScrollDelay) {
            window.falseScroll = true;
            textRef.current.scrollTo({
                top: (outputDisplayH / 100) * percent - clientHeight / 2 + 50,
            });
            clearTimeout(window.falseScrollTimeout);
            window.falseScrollTimeout = setTimeout(() => {
                window.falseScroll = false;
            }, 700);
        }
    };

    useEffect(() => {
        calculateDotPos();
    }, [width, height]);

    return (
        <>
            <div ref={textRef} onScroll={onScroll} className={cl.textDisplay}>
                {needScrollDot && (
                    <div
                        className={cl.dot}
                        style={{
                            top:
                                outputDisplayStats.current.top +
                                (outputDisplayStats.current.height / 100) *
                                    dotPosTop,
                            left:
                                outputDisplayStats.current.left +
                                outputDisplayStats.current.width,
                        }}
                    />
                )}
                {textArr.map((s) => (
                    <Sentence
                        key={s.key || 'last'}
                        sentence={s}
                        calculateDotPos={calculateDotPos}
                    />
                ))}
            </div>
        </>
    );
};

export default observer(TextDisplay);
