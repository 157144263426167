import React from 'react';
import cl from './NotifyChip.module.css';
import { getTimeWithTimezone } from '../../helpers';

const NotifyChip = ({ notifyObj, isActive, onClick }) => {
    const { title, time } = notifyObj;

    const getClassName = () => {
        const classNames = [cl.notify];
        if (isActive) classNames.push(cl.active);

        return classNames.join(' ');
    };

    return (
        <div className={getClassName()} onClick={onClick}>
            <span>{title}</span>
            <span>{`(${getTimeWithTimezone(time)})`}</span>
        </div>
    );
};

export default NotifyChip;
