import { makeAutoObservable } from 'mobx';
import i18n from 'i18next';
import { interfaceLangs } from '../data/interfaceLangs';
import LanguagesService from '../api/LanguagesService';

const DefaultLanguage = {
    code: 'en',
    name: 'English',
};

export default class Interface {
    constructor() {
        makeAutoObservable(this, {}, { autoBind: true, deep: true });

        this.languages = [];
        this.availableLanguages = [];

        const browserLang = navigator?.languages[0]?.slice(0, 2);
        const savedLanguage = localStorage.getItem('i18nextLng');
        const currentLanguage =
            savedLanguage || browserLang || DefaultLanguage.code;

        this.initialize(currentLanguage);
    }

    async initialize(currentLanguage) {
        this.setLanguage(currentLanguage);
        await this.getAllLanguages();
        await this.getAvailableLanguages(currentLanguage);
    }

    setLanguage(lang) {
        if (!lang) return;
        if (typeof lang === 'object') lang = lang.value;

        const selectedLang = interfaceLangs.find(
            (iLang) => iLang.value === lang
        );
        if (selectedLang) {
            i18n.changeLanguage(selectedLang.value);
            this.language = selectedLang.value;
        }
    }

    checkIfCurrentLangAvailable({ current, allCodes }) {
        if (!current || !allCodes.includes(current)) {
            this.setLanguage(DefaultLanguage.code);
        } else {
            this.setLanguage(current);
        }
    }

    async getAllLanguages() {
        try {
            const { data } = await LanguagesService.getAllLanguages({
                ready: null,
            });
            if (!data.map) return;
            this.languages = data
                .map((lObj) => ({
                    value: lObj.code.toLowerCase(),
                    name: lObj.title,
                }))
                .sort((a, b) => a.value.localeCompare(b.value));
        } catch (e) {
            this.languages = [
                { value: DefaultLanguage.code, name: DefaultLanguage.name },
            ];
        }
    }

    async getAvailableLanguages(currentLanguage) {
        try {
            const { data } = await LanguagesService.getAllLanguages({
                ready: true,
            });
            if (!data.map) return;
            this.availableLanguages = data
                .map((lObj) => ({
                    value: lObj.code.toLowerCase(),
                    name: lObj.title,
                }))
                .sort((a, b) => a.value.localeCompare(b.value));

            this.checkIfCurrentLangAvailable({
                current: currentLanguage,
                allCodes: data.map((d) => d.code.toLowerCase()),
            });
        } catch (e) {
            this.availableLanguages = [
                { value: DefaultLanguage.code, name: DefaultLanguage.name },
            ];
        }
    }
}
