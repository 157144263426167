import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import onboardingStore from '../../../../store/onboarding';
import { auth } from '../../../../App';
import Modal from '../../../../components/UI/Modal/Modal';
import {
    Button,
    CloseButton,
    MicroButton,
} from '../../../../teacherComponents';
import { BaseUrl } from '../../../../data/common';
import { ReactComponent as IconAdd } from '../../../../assets/svg/lessons-add.svg';
import {
    OnboardingWrapper,
    OnboardingModal,
    OnboardingKeys,
    BubblePositions,
    ArrowPositions,
} from '../../../../teacherComponents/Onboarding';
import { DownloadButtons } from '../../../TeachersTutorial/components';
import cl from './AddStudentModal.module.css';

const AddStudentModal = () => {
    const { t } = useTranslation();

    const { user } = auth;

    const [visible, setVisible] = useState(false);
    const [message, setMessage] = useState(false);

    const joinUrl = `${BaseUrl}/follow-teacher?id=${user?.id}`;

    const handleCopy = () => {
        navigator.clipboard
            .writeText(joinUrl)
            .then(() => {
                setMessage(t('exercises.add_student_alert'));
            })
            .catch((err) => {
                setMessage(`${t('exercises.add_student_error')}(${err})`);
            });
    };

    useEffect(() => {
        setMessage(false);
    }, [visible]);

    return (
        <>
            <Modal
                visible={visible}
                setVisible={setVisible}
                className={cl.modalInner}
            >
                <p className={cl.title}>{t('exercises.add_student')}</p>
                <p className={cl.subtitle}>
                    {t('exercises.add_student_description')}
                </p>
                <div className={cl.linkContainer} onClick={handleCopy}>
                    <p className={cl.linkLabel}>
                        {t('exercises.student_link')}
                    </p>
                    <p>{joinUrl}</p>
                </div>
                <DownloadButtons size={'small'} />
                <Button
                    style={{
                        pointerEvents: message ? 'none' : 'all',
                        width: '100%',
                        marginTop: 20,
                    }}
                    variant={message ? 'grey' : 'dark'}
                    onClick={handleCopy}
                    text={message || t('buttons.copy_link')}
                />

                <CloseButton onClick={() => setVisible(false)} />
            </Modal>
            <OnboardingWrapper
                onboardingKey={onboardingStore.isOnboarding(
                    OnboardingKeys.AddStudent
                )}
                title={t('teacher_onboarding.press_here')}
                bubblePosition={{
                    x: BubblePositions.x.Center,
                    y: BubblePositions.y.Bottom,
                }}
                delay={500}
                onConfirm={() => onboardingStore.setTemporaryStudent(true)}
                arrowPosition={ArrowPositions.Bottom}
            >
                <div
                    className={cl.buttonContainer}
                    onClick={() => setVisible(true)}
                >
                    <MicroButton
                        icon={<IconAdd />}
                        variant={'grey'}
                        size={'large'}
                        isDisabled={!user?.id}
                    />
                    <p>{t('exercises.add_student')}</p>
                </div>
            </OnboardingWrapper>
            <OnboardingModal
                onboardingKey={onboardingStore.isOnboarding(
                    OnboardingKeys.StudentsInfo
                )}
                title={t('teacher_onboarding.students_info')}
                subtitle={t('teacher_onboarding.students_info_subtitle')}
                delay={500}
            />
        </>
    );
};

export default observer(AddStudentModal);
