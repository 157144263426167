import React from 'react';
import { ReactComponent as IconSearch } from '../../assets/svg/lessons-search.svg';
import DemoPageLoader from '../../components/UI/DemoPageLoader/DemoPageLoader';
import { ReactComponent as IconClose } from '../../assets/svg/lessons-cross_white.svg';
import Button from '../Button/Button';
import cl from './Input.module.css';

const Input = ({
    placeholder,
    value,
    onChange,
    onEnterKey,
    onBlur,
    onFocus,
    style,
    type,
    color,
    disabled,
    autoComplete,
    autoFocus,
    search,
    variant,
    isLoading,
    showReset,
}) => {
    const classNames = [cl.input];

    if (color) {
        color = Array.isArray(color) ? color : color.split(' ');
        color.forEach((el) => {
            classNames.push(cl[el]);
        });
    }

    if (variant) classNames.push(cl[variant]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.target.blur();
            if (onEnterKey) {
                onEnterKey(e);
            }
        }
    };

    const handleChange = (e) => {
        onChange(e.target.value, e);
    };

    const handleResetButton = () => {
        onChange('');
    };

    return (
        <div className={cl.container}>
            {isLoading ? (
                <DemoPageLoader size={20} color="#e3e3e3" />
            ) : (
                <input
                    autoComplete={autoComplete}
                    disabled={disabled}
                    style={style}
                    placeholder={placeholder}
                    value={value}
                    type={type || 'text'}
                    className={classNames.join(' ')}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    autoFocus={autoFocus}
                />
            )}
            {search && (
                <div className={cl.icon}>
                    <IconSearch />
                </div>
            )}
            {showReset && value && (
                <div className={cl.resetButton}>
                    <Button
                        variant={'dark'}
                        size={'tiny'}
                        icon={<IconClose style={{ width: 22 }} />}
                        onClick={handleResetButton}
                    />
                </div>
            )}
        </div>
    );
};

export default Input;
