import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, PrimaryButton, PrimaryInput } from '../../../../UI';
import Modal from '../../../../components/UI/Modal/Modal';
import cl from './Localizations.module.css';

const Localizations = ({
    languages,
    localizations,
    setLocalizations,
    onSave,
    isLoading,
}) => {
    const { t } = useTranslation();

    const [editLang, setEditLang] = useState();
    const [visible, setVisible] = useState(false);

    const toggleEditMode = (lang) => {
        if (lang === editLang) {
            onSave();
            setEditLang();
        } else {
            setEditLang(lang);
        }
    };

    const isEdit = (lang) => editLang === lang;

    const setLocalizationTitle = (title) => {
        setLocalizations(
            localizations.map((l) =>
                l.lang === editLang ? { ...l, title } : l
            )
        );
    };

    const setLocalizationText = (text) => {
        setLocalizations(
            localizations.map((l) => (l.lang === editLang ? { ...l, text } : l))
        );
    };

    useEffect(() => {
        setEditLang();
    }, [visible]);

    return (
        <>
            <PrimaryButton
                style={{ marginLeft: 'auto' }}
                variant={'light'}
                text={t('notify.show_localizations')}
                onClick={() => setVisible(true)}
                isDisabled={!localizations.length}
            />
            <Modal visible={visible} setVisible={setVisible}>
                <div className={cl.localizations}>
                    {isLoading && (
                        <div className={cl.loaderContainer}>
                            <Loader />
                        </div>
                    )}
                    {localizations.map((l) => (
                        <div className={cl.localization} key={l.lang}>
                            <div className={cl.section}>
                                <span
                                    className={cl.label}
                                >{`${t('notify.language')}: `}</span>
                                <span className={cl.text}>
                                    {
                                        languages.find(
                                            (lObj) => lObj.value === l.lang
                                        ).name
                                    }
                                </span>
                            </div>
                            <div className={cl.section}>
                                <span
                                    className={cl.label}
                                >{`${t('notify.title')}: `}</span>
                                {isEdit(l.lang) ? (
                                    <PrimaryInput
                                        value={l.title}
                                        placeholder={t('notify.text')}
                                        onChange={setLocalizationTitle}
                                    />
                                ) : (
                                    <span className={cl.text}>{l.title}</span>
                                )}
                            </div>
                            <div className={cl.section}>
                                <span
                                    className={cl.label}
                                >{`${t('notify.text')}: `}</span>
                                {isEdit(l.lang) ? (
                                    <PrimaryInput
                                        value={l.text}
                                        placeholder={t('notify.text')}
                                        onChange={setLocalizationText}
                                    />
                                ) : (
                                    <span className={cl.text}>{l.text}</span>
                                )}
                            </div>
                            <PrimaryButton
                                text={t(
                                    isEdit(l.lang)
                                        ? 'buttons.save'
                                        : 'buttons.edit'
                                )}
                                onClick={() => toggleEditMode(l.lang)}
                                variant={isEdit(l.lang) ? 'green' : 'light'}
                                style={{
                                    position: 'absolute',
                                    right: 6,
                                    top: 6,
                                }}
                            />
                        </div>
                    ))}
                </div>
            </Modal>
        </>
    );
};

export default Localizations;
