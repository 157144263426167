import { makeAutoObservable, toJS } from 'mobx';
import { dots } from '../data/punctuation';
import TrackService from '../api/TrackService';
const TICKS_IN_SECOND = 10000000;
class Player {
    rewardAfterReport = false;
    isInaccuracySended = false;
    isTranslationInaccuracySended = false;
    blurOnPause = localStorage.getItem('blurOnPause') === 'true' ? true : false;
    blurTranslation =
        localStorage.getItem('blurTranslation') === 'true' ? true : false;
    textOverlay = false;
    translationOverlay = false;
    synthesizeTranslation =
        localStorage.getItem('synthesizeTranslation') === 'true' ? true : false;
    showText = localStorage.getItem('showText') === 'true' ? true : false;
    textOnPause = localStorage.getItem('textOnPause') === 'true' ? true : false;
    showTranslation =
        localStorage.getItem('showTranslation') === 'false' ? false : true;
    translationOnPause =
        localStorage.getItem('translationOnPause') === 'true' ? true : false;
    pauseAfterEach = false;

    isLandscape = false;
    isTouch = false;
    currentTime = 0;
    lastTranslation = '';
    trackLength = 0;
    translatedFromSentence = {};
    isWasChanges = false;
    playbackSpeed = '1x';
    htmlForTranslation = '';
    text = '...READY TO RECOGNIZE...';
    maxTime = null;
    textArr = ['...READY TO RECOGNIZE...'];
    duration = 0;
    problemsComment = '';
    description = null;
    currentSentenceObj = {};
    currentActiveWordsArr = [];
    pHeightsOriginal = [];
    pHeightsTranslations = [];
    finalHeights = [];
    fontSize = 14;
    translationFontSize = 14;
    wasPlayed = false;
    isLoaded = false;
    podcastLink = '';

    editingSentence = null;
    contentTitle = '';
    image = '';
    contentId = '';
    isLoading = false;
    textToTranslate = null;
    highlightTranslation = null;
    wordToTranslate = null;
    isTranslationEditMode = false;
    isEditMode = false;
    youtubeLink = '';
    podcastId = '';
    podcastName = '';
    percentOfRecognition = null;
    isRecognizing = false;
    sourceLang = '';
    targetlanguage = 'en';
    fileSrc = null;
    audioPath = null;
    ytVideoId = null;
    showPlayer = false;
    isPlaying = false;
    showSourceLangChooser = false;
    playerRef = null;
    history = [];
    currentHistoryPlace = 0;
    difficult = null;
    dialect = null;
    topic = null;
    subTopic = null;
    slang = null;
    tags = [];
    categories = [];
    timeOffset = 0;
    wasForwarded = false;

    resetStates() {
        this.rewardAfterReport = false;
        this.isInaccuracySended = false;
        this.isTranslationInaccuracySended = false;
        this.lastTranslation = '';
        this.podcastLink = '';
        this.currentTime = 0;
        this.trackLength = 0;
        this.timeOffset = 0;
        this.showTranslation = true;
        this.playbackSpeed = '1x';
        this.translatedFromSentence = {};
        this.htmlForTranslation = '';
        this.text = '...READY TO RECOGNIZE...';
        this.maxTime = null;
        this.textArr = ['...READY TO RECOGNIZE...'];
        this.duration = 0;
        this.isWasChanges = false;
        this.problemsComment = '';
        this.description = null;
        this.currentSentenceObj = {};
        this.currentActiveWordsArr = [];
        this.pHeightsOriginal = [];
        this.pHeightsTranslations = [];
        this.finalHeights = [];
        this.fontSize = 14;
        this.translationFontSize = 14;
        this.wasPlayed = false;
        this.isLoaded = false;
        this.editingSentence = null;
        this.contentTitle = '';
        this.image = '';
        this.contentId = '';
        this.isLoading = false;
        this.textToTranslate = null;
        this.wordToTranslate = null;
        this.youtubeLink = '';
        this.podcastId = '';
        this.podcastName = '';
        this.percentOfRecognition = null;
        this.isRecognizing = false;
        this.sourceLang = '';
        this.fileSrc = null;
        this.audioPath = null;
        this.ytVideoId = null;
        this.showPlayer = false;
        this.isPlaying = false;
        this.showSourceLangChooser = false;
        this.playerRef = null;
        this.history = [];
        this.currentHistoryPlace = 0;
        this.difficult = null;
        this.dialect = null;
        this.topic = null;
        this.subTopic = null;
        this.slang = null;
        this.tags = [];
        this.categories = [];
        this.wasForwarded = false;
        this.wasPaused = false;
    }

    constructor() {
        this.targetlanguage = localStorage.getItem('targetLanguage') || 'en';
        makeAutoObservable(this, {}, { autoBind: true, deep: true });
    }

    setPlaybackSpeed(val) {
        this.playbackSpeed = val;
        if (this.playerRef.setPlaybackRate) {
            this.playerRef.setPlaybackRate(parseFloat(val));
        } else {
            this.playerRef.playbackRate = parseFloat(val);
        }
    }

    setRewardAfterReport(val) {
        this.rewardAfterReport = val;
    }

    setIsInaccuracySended(val) {
        this.isInaccuracySended = val;
    }

    setIsTranslationInaccuracySended(val) {
        this.isTranslationInaccuracySended = val;
    }

    sendInaccuracy(isForHumanEdit = false, isTranslation = false) {
        if (Object.keys(this.currentSentenceObj).length) {
            if (isTranslation) {
                this.setIsTranslationInaccuracySended(true);
            } else {
                this.setIsInaccuracySended(true);
            }
            const sendFunc = isTranslation
                ? TrackService.addTranslationInaccuracy
                : TrackService.addInaccuracy;
            sendFunc({
                id: this.contentId,
                time: parseInt(this.currentTime),
                isForHumanEdit: isForHumanEdit,
                lang: this.targetlanguage,
                offset: parseInt(this.currentSentenceObj.offset),
            })
                .then((res) => {
                    const data = res.data;
                    if (data.sentences) {
                        this.setDisplayText(data.sentences);
                        this.setRewardAfterReport({
                            sentences: data.sentences,
                            time: data.inaccuracy.time,
                        });
                    } else {
                        this.setRewardAfterReport({});
                    }
                })
                .catch((_e) => {});
        }
    }

    setLastTranslation(val) {
        this.lastTranslation = val;
    }

    setBlurOnPause(val) {
        this.blurOnPause = val;
        localStorage.setItem('blurOnPause', val);
    }

    setBlurTranslation(val) {
        this.blurTranslation = val;
        localStorage.setItem('blurTranslation', val);
    }

    setTextOverlay(val) {
        this.textOverlay = val;
    }

    setTranslationOverlay(val) {
        this.translationOverlay = val;
    }

    setTranslationOnPause(val) {
        this.translationOnPause = val;
        localStorage.setItem('translationOnPause', val);
    }

    setIsLandscape(val) {
        this.isLandscape = val;
    }

    setIsTouch(val) {
        this.isTouch = val;
    }

    setSynthesizeTranslation(val) {
        this.synthesizeTranslation = val;
        localStorage.setItem('synthesizeTranslation', val);
    }

    setShowText(val) {
        this.showText = val;
        localStorage.setItem('showText', val);
    }

    setTextOnPause(val) {
        this.textOnPause = val;
        localStorage.setItem('textOnPause', val);
    }

    setShowTranslation(val) {
        this.showTranslation = val;
        localStorage.setItem('showTranslation', val);
    }

    setPauseAfterEach(val) {
        this.pauseAfterEach = val;
    }

    setIsWasChanges(val) {
        this.isWasChanges = val;
    }

    editTranslation(index, text) {
        this.textArr[index].translations[this.targetlanguage] = text;
    }

    undoRedoHistory(dir) {
        let newPlace = null;
        if (dir === 'undo') {
            newPlace = this.currentHistoryPlace - 1;
        } else if (dir === 'redo') {
            newPlace = this.currentHistoryPlace + 1;
        }
        if (newPlace < 0) newPlace = 0;
        if (newPlace >= this.history.length - 1)
            newPlace = this.history.length - 1;
        this.setCurrentHistoryPlace(newPlace);
    }

    addElToHistory(el) {
        const newHistory = [];
        this.history.forEach((elem, i) => {
            if (i <= this.currentHistoryPlace) {
                newHistory.push(toJS(elem));
            }
        });
        newHistory.push(toJS(el));
        this.setHistory(newHistory);
    }

    setHistory(history) {
        const MAX_HISTORY_LENGTH = 20;
        if (history.length > MAX_HISTORY_LENGTH) {
            history = history.splice(MAX_HISTORY_LENGTH * -1);
        }
        this.history = history;
        this.currentHistoryPlace = history.length - 1;
    }

    setCurrentHistoryPlace(i) {
        let newState = this.history[i];
        if (!newState) {
            newState = this.history[this.history.length - 1];
        }
        if (!newState) {
            return;
        }
        this.currentHistoryPlace = i;
        this.textArr = [...newState];
    }

    setEditingSentence(i) {
        if (i < this.textArr.length) {
            this.editingSentence = i;
            if (i !== null) {
                this.handleChangeAudioTime(this.textArr[i].offset, true);
                return this.textArr[i];
            }
        }
    }

    setPHeights(heights, isTranslations) {
        if (isTranslations) {
            this.pHeightsTranslations = heights;
        } else {
            this.pHeightsOriginal = heights;
        }
        this.finalHeights = [];
        if (this.pHeightsOriginal.length && this.pHeightsTranslations.length) {
            const arr = [];
            for (let i = 0; i < this.pHeightsOriginal.length; i++) {
                const element = this.pHeightsOriginal[i];
                let h = element;
                if (this.pHeightsTranslations[i] > h) {
                    h = this.pHeightsTranslations[i];
                }
                arr.push(h);
            }
            this.setFinalHeights([...this.finalHeights, ...arr]);
        }
    }

    setCategories(categories) {
        this.categories = categories;
    }

    setTags(tags) {
        this.tags = tags;
    }

    setPodcastLink(link) {
        this.podcastLink = link;
    }

    setDifficult(difficult) {
        this.difficult = difficult;
    }

    setSubTopic(subTopic) {
        this.subTopic = subTopic;
    }

    setTopic(topic) {
        this.topic = topic;
    }

    setSlang(slang) {
        this.slang = slang;
    }

    setDialect(dialect) {
        this.dialect = dialect;
    }

    setFinalHeights(h) {
        this.finalHeights = h;
    }

    setResetHeights() {
        this.finalHeights = [];
        this.pHeightsOriginal = [];
        this.pHeightsTranslations = [];
    }

    setFontSize(size) {
        this.setResetHeights();
        this.fontSize = size;
    }

    setTranslationFontSize(size) {
        this.translationFontSize = size;
    }

    setPlayerRef(player) {
        this.playerRef = player;
    }

    setImage(link) {
        this.image = link;
    }

    setDescription(desc) {
        this.description = desc;
    }

    setDuration(duration) {
        this.duration = duration;
    }

    setProblemsComment(val) {
        this.problemsComment = val;
    }

    setContentTitle(state) {
        this.contentTitle = state;
    }

    setContentId(state) {
        this.contentId = state;
    }

    setIsLoading(state) {
        this.isLoading = state;
    }

    setTextToTranslate(text) {
        if (!text) return;
        this.textToTranslate = text;
    }

    setWordToTranslate(state, sentenceObject) {
        this.wordToTranslate = state;
        if (sentenceObject) this.translatedFromSentence = sentenceObject;
    }

    setIsEditMode(bool) {
        this.isEditMode = bool;
    }

    setCurrentActiveWordsArr(arr) {
        if (arr) {
            const p = arr[0].offsetParent;
            const words = p.childNodes;
            let htmlForTranslation = '';
            let tagOpened = false;
            let dotAfterText = '';
            let colonAfterWord = '';
            for (let i = 0; i < words.length; i++) {
                const w = words[i];
                dotAfterText = '';
                if (w.tagName !== 'SPAN') continue;
                let text = w.textContent;
                if (dots.includes(text.substr(-1))) {
                    dotAfterText = text.slice(-1);
                    text = text.slice(0, -1);
                }
                if ([','].includes(text.substr(-1))) {
                    colonAfterWord = text.slice(-1);
                    text = text.slice(0, -1);
                }
                if (!tagOpened && arr.includes(w)) {
                    htmlForTranslation += '<span>' + text;
                    tagOpened = true;
                } else if (tagOpened && !arr.includes(w)) {
                    htmlForTranslation +=
                        '</span>' + colonAfterWord + ' ' + text + ' ';
                    if (colonAfterWord) colonAfterWord = '';
                    tagOpened = false;
                } else if (tagOpened) {
                    htmlForTranslation += ' ' + text;
                } else {
                    htmlForTranslation += text + ' ';
                }
            }
            if (tagOpened) htmlForTranslation += '</span>';
            htmlForTranslation = htmlForTranslation.trim();
            htmlForTranslation += dotAfterText;
            this.htmlForTranslation = htmlForTranslation;

            this.currentActiveWordsArr = Array.from(arr);
        } else {
            this.currentActiveWordsArr = [];
            this.htmlForTranslation = '';
        }
    }

    setIsTranslationEditMode(bool) {
        this.isTranslationEditMode = bool;
    }

    setYoutubeLink(state) {
        this.youtubeLink = state;
    }

    setPodcastId(state) {
        this.podcastId = state;
    }

    setPodcastName(name) {
        this.podcastName = name;
    }

    setPercentOfRecognition(state) {
        this.percentOfRecognition = state;
    }

    setIsRecognizing(state) {
        this.isRecognizing = state;
    }

    setSourceLang(state) {
        this.sourceLang = state;
    }

    setTargetLanguage(lang) {
        localStorage.setItem('targetLanguage', lang);
        this.targetlanguage = lang;
    }

    setAudioPath(state) {
        this.audioPath = state;
    }

    setFileSrc(state) {
        this.fileSrc = state;
    }

    setTimeOffset(offset) {
        this.timeOffset = offset;
    }

    setYtVideoId(state) {
        this.ytVideoId = state;
    }

    setShowPlayer(state) {
        this.showPlayer = state;
    }

    setIsPlaying(state) {
        this.isPlaying = state;
    }

    setShowSourceLangChooser(state) {
        this.showSourceLangChooser = state;
    }

    setCurrentTime(time) {
        this.currentTime = time;
        this.computeTextHightLight();
    }

    setTrackLength(length) {
        this.trackLength = length;
    }

    handlePlayPauseClick() {
        this.isPlaying ? this.playerRef.pause() : this.playerRef.play();
    }

    setIsLoaded(flag) {
        this.isLoaded = flag;
    }

    handleChangeAudioTime(time, changeTrackProgress = false) {
        if (isNaN(time)) return;
        if (this.playerRef === null) return;

        if (changeTrackProgress) {
            const trackTime = time / TICKS_IN_SECOND;
            this.playerRef.currentTime = trackTime;
            if (this.playerRef.seek) {
                this.playerRef.seek(trackTime);
            }
            this.setCurrentTime(time);
        } else {
            this.setCurrentTime(time * TICKS_IN_SECOND);
        }
    }

    getIndexOfSentence(sentenceObject) {
        let index = null;
        this.textArr.forEach((el, i) => {
            if (el?.key === sentenceObject?.key) index = i;
        });
        return index;
    }

    setSplitSentence(sentenceObject, newText, dotWasAdded = false) {
        const index = this.getIndexOfSentence(sentenceObject);
        if (index === null) return;
        const sentences = [{ words: [] }];
        const words = newText.split(' ');
        words.forEach((w, i) => {
            const wordToAdd = sentenceObject.words[i];
            const lastChar = w.substr(-1);
            const isWithDot = dots.includes(lastChar);
            const notLastWordOfLastSentence = i + 1 < words.length;
            if (isWithDot && notLastWordOfLastSentence && !dotWasAdded)
                wordToAdd.text = wordToAdd.text + lastChar;
            sentences[sentences.length - 1].words.push(wordToAdd);
            if (isWithDot && notLastWordOfLastSentence) {
                sentences.push({ words: [] });
                let nextWord = sentenceObject.words[i + 1].text;
                if (nextWord) {
                    nextWord = nextWord[0].toUpperCase() + nextWord.substr(1);
                    sentenceObject.words[i + 1].text = nextWord;
                }
            }
        });

        for (let i = 0; i < sentences.length; i++) {
            const el = sentences[i];
            if (el) {
                const firstWord = el.words[0];
                const lastWord =
                    el.words[el.words.length - 1] ||
                    el.words[el.words.length - 2];
                el.offset = firstWord.offset;
                el.duration = lastWord.offset + lastWord.duration - el.offset;
                el.text = el.words.map((el) => el.text).join(' ');
                el.key =
                    el.words.map((el) => el.text).join('_') + '_' + el.offset;
                el.translations = {};
                sentences[i] = el;
            }
        }

        this.textArr.splice(index, 1, ...sentences);
        return index + 1;
    }

    setRemoveSentence(sentenceObject) {
        const index = this.getIndexOfSentence(sentenceObject);
        if (index !== null) {
            this.textArr.splice(index, 1);
            return index - 1 >= 0 ? index - 1 : 0;
        }
    }

    setJoinTwoSenteces(sentenceObject, newText) {
        const index = this.getIndexOfSentence(sentenceObject);
        if (!newText) {
            newText =
                sentenceObject.text.substr(0, sentenceObject.text.length - 1) +
                '';
        }
        const nextSentence = { ...this.textArr[index + 1] };
        if (index !== null && nextSentence !== undefined) {
            let nextSentenceText = nextSentence.text;
            nextSentenceText =
                nextSentenceText[0].toLowerCase() + nextSentenceText.substr(1);
            nextSentenceText = ' ' + nextSentenceText;
            newText += nextSentenceText;
            let newWords = nextSentence.words;
            newWords = newWords.filter((w) => {
                return w.text.length > 0;
            });
            newWords[0].text =
                newWords[0].text[0].toLowerCase() + newWords[0].text.substr(1);
            let lastOldWordText =
                sentenceObject.words[sentenceObject.words.length - 1].text;
            sentenceObject.words[sentenceObject.words.length - 1].text =
                lastOldWordText.substr(0, lastOldWordText.length - 1);
            newWords = [...sentenceObject.words].concat(newWords);
            sentenceObject.words = newWords;
            sentenceObject.key =
                newWords.map((el) => el.text).join('_') +
                '_' +
                sentenceObject.offset;
            sentenceObject.text = newText;
            sentenceObject.duration =
                nextSentence.duration + sentenceObject.duration;
            sentenceObject.translations = {};
            this.textArr.splice(index, 2, sentenceObject);
            return index;
        }
    }

    setCurrentSentenceObj(obj) {
        if (obj) {
            this.currentSentenceObj = obj;
            this.setTextToTranslate(obj.text);
            this.setIsInaccuracySended(false);
            this.setIsTranslationInaccuracySended(false);
        } else {
            this.currentSentenceObj = null;
        }
    }

    calculateMinusOffset(prev, current) {
        const lastWord = prev.words.slice(-1)[0];
        if (!lastWord) return 0;
        const timeBetween =
            current.offset - (lastWord.duration + lastWord.offset);
        return Math.min(
            timeBetween - TICKS_IN_SECOND / 10,
            TICKS_IN_SECOND / 3
        );
    }

    computeTextHightLight(arr = false) {
        if (arr) {
            this.textArr = arr;
        }
        let lastActiveElIndex = null;
        let lastSpelledElIndex = null;
        for (let i = 0; i < this.textArr.length; i++) {
            const sentence = this.textArr[i];
            if (typeof sentence === 'string') return;

            const prevSentence = this.textArr[i - 1];
            const { offset, duration } = sentence;
            const minusOffset = prevSentence
                ? this.calculateMinusOffset(prevSentence, sentence)
                : 0;
            const active = this.isActiveOrSpelled(
                offset - minusOffset,
                duration + minusOffset,
                this.currentTime
            );
            if (active === true) {
                lastActiveElIndex = i;
            }
            if (active === 'spelled') {
                lastSpelledElIndex = i;
            }
        }
        for (let i = 0; i < this.textArr.length; i++) {
            if (i < lastActiveElIndex) {
                this.textArr[i].active = 'spelled';
            } else if (i === lastActiveElIndex) {
                this.textArr[i].active = true;
            } else {
                this.textArr[i].active = false;
            }
        }

        if (lastActiveElIndex === null && lastSpelledElIndex !== null) {
            this.textArr[lastSpelledElIndex].active = true;
        }
        for (let i = 0; i < this.textArr.length; i++) {
            const el = this.textArr[i];
            if (el.active !== true) {
                for (let k = 0; k < this.textArr[i].words.length; k++) {
                    el.words[k].active = false;
                }
                continue;
            }
            for (let k = 0; k < el.words.length; k++) {
                const isActive = this.isActiveOrSpelled(
                    el.words[k].offset,
                    el.words[k].duration,
                    this.currentTime
                );
                if (isActive === 'spelled' || isActive === true) {
                    el.words[k].active = true;
                } else {
                    el.words[k].active = false;
                }
                if (
                    this.currentTime >
                    this.textArr[i].offset + this.textArr[i].duration
                ) {
                    el.words[k].active = false;
                }
            }
            this.textArr[i] = { ...el };
        }
    }

    setMaxTime(time) {
        this.maxTime = time;
    }

    setDisplayText(text, pushInsteadReplace = false) {
        let newText;
        if (pushInsteadReplace) {
            if (Array.isArray(this.text) && typeof text === 'string') {
                this.text = this.text.push('...RECOGNITION STOPPED...');
            } else if (typeof text === 'string') {
                this.text = [text];
            } else if (Array.isArray(text)) {
                this.text = text;
            }
        } else {
            if (typeof text === 'string') {
                newText = [text];
                this.maxTime = null;
            } else {
                newText = text;
            }
        }
        let needCompute = false;
        const _text = newText.map((elem) => {
            if (typeof elem === 'string') return elem;
            needCompute = true;
            return elem;
        });
        if (needCompute) {
            this.computeTextHightLight(_text);
            const lastItem = this.textArr[this.textArr.length - 1];
            this.maxTime = lastItem.offset;
        } else {
            this.textArr = _text;
        }
    }

    setActionsOnRoleChange(role) {
        if (role === 'user') {
            this.setIsEditMode(false);
            this.setIsTranslationEditMode(false);
        } else if (role === 'editor') {
            this.setIsEditMode(true);
            this.setIsTranslationEditMode(false);
        } else if (role === 'translator') {
            this.setIsEditMode(false);
            this.setIsTranslationEditMode(true);
        } else if (role === 'admin') {
            this.setIsEditMode(false);
            this.setIsTranslationEditMode(false);
        } else if (role === 'educator') {
            this.setIsEditMode(false);
            this.setIsTranslationEditMode(false);
        }
    }

    isActiveOrSpelled(offset, duration, time) {
        const plusOffset = this.timeOffset * 1000 * 10000;
        time += plusOffset;
        if (
            typeof offset !== 'number' ||
            typeof duration !== 'number' ||
            typeof time !== 'number'
        )
            return false;
        if (time >= offset && time <= offset + duration) {
            return true;
        } else if (time >= offset + duration) {
            return 'spelled';
        } else {
            return false;
        }
    }

    setWasForwarded(flag) {
        this.wasForwarded = flag;
    }

    setWasPaused(flag) {
        this.wasPaused = flag;
    }

    setHighlightTranslation(val) {
        this.highlightTranslation = val;
    }

    parseTrackData(json) {
        this.setContentTitle(json.title);
        this.setContentId(json.id);
        this.setImage(json.image?.md);
        this.setDifficult(json.difficulty);
        this.setShowPlayer(true);
        this.setDuration(json.duration);
        this.setTrackLength(json.duration * TICKS_IN_SECOND);
        this.setYtVideoId(json.externalId);
        this.setSourceLang(json.lang);
        if (json.recognized && !json.hasErrors) {
            this.setResetHeights();
            this.setIsLoading(false);
            this.setIsRecognizing(false);
            this.setMaxTime(null);
            this.setPercentOfRecognition(null);
        } else {
            this.setIsRecognizing(true);
            this.setIsLoading(true);
            this.setPercentOfRecognition(json.percentOfRecognition);
        }
        this.setDisplayText(json.sentences || '');
    }
}

export default new Player();
