import React, { useContext, useState } from 'react';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFetching } from '../../hooks/useFetching';
import { AuthContext } from '../../App';
import PageWrapper from '../../components/UI/PageWrapper/PageWrapper';
import { Button, Input, Label } from '../../teacherComponents';
import isEmailValid from '../../utils/isEmailValid';
import { Loader } from '../../UI';
import Modal from '../../components/UI/Modal/Modal';
import cl from './Login.module.css';
import { AuthModes } from '../../store/auth';

const Login = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { auth } = useContext(AuthContext);

    const [formErrors, setFormErrors] = useState({});

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [loginUser, isLoading, loginError, resetError] = useFetching(
        async () => {
            await auth.login(email, password, navigate);
        }
    );

    const handleLogin = () => {
        setFormErrors({});
        setTimeout(() => {
            const errors = {
                email: !isEmailValid(email),
                password: !password,
            };
            setFormErrors(errors);

            if (Object.values(errors).some((e) => e)) return;
            loginUser();
        });
    };

    const handleErrorModalClose = () => {
        setPassword('');
        resetError();
    };

    const handleRegisterRedirect = () => {
        auth.setAuthMode(AuthModes.Register);
    };

    const renderSignUpButton = () => {
        return (
            <p className={cl.signInButton}>
                {parse(t('login.register_redirect'), {
                    replace: (node) => {
                        if (node.name === 'span') {
                            return (
                                <span onClick={handleRegisterRedirect}>
                                    {node.children[0].data}
                                </span>
                            );
                        }
                    },
                })}
            </p>
        );
    };

    return (
        <PageWrapper additionalClass={cl.registerContainer}>
            {isLoading && (
                <Loader
                    style={{
                        position: 'absolute',
                        top: 'calc(50% + 20px)',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 2,
                    }}
                />
            )}
            <div className={cl.container}>
                <div className={cl.numbersLeft} />
                <div className={cl.numbersRight} />
                <div className={cl.containerInner}>
                    <div className={cl.form}>
                        <p className={cl.registrationTitle}>
                            {t('login.title')}
                        </p>
                        <div style={{ marginBottom: 6 }}>
                            <Label
                                text={t('email')}
                                isError={formErrors['email']}
                            />
                            <Input
                                variant={
                                    formErrors['email'] ? 'errored' : 'outlined'
                                }
                                value={email}
                                onChange={(val) => setEmail(val.trim())}
                                type="text"
                            />
                        </div>
                        <div style={{ marginBottom: 36 }}>
                            <Label
                                text={t('login.password')}
                                isError={formErrors['password']}
                            />
                            <Input
                                variant={
                                    formErrors['password']
                                        ? 'errored'
                                        : 'outlined'
                                }
                                value={password}
                                onChange={setPassword}
                                type="password"
                            />
                        </div>
                        {renderSignUpButton()}
                        <div className={cl.buttons}>
                            <Button
                                variant={'purple'}
                                text={t('login.login')}
                                onClick={handleLogin}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                visible={loginError}
                title={t('errors.incorrect_login_or_password')}
            >
                <Button
                    style={{ margin: 'auto' }}
                    variant={'purple'}
                    text={t('buttons.ok')}
                    onClick={handleErrorModalClose}
                />
            </Modal>
        </PageWrapper>
    );
};

export default Login;
